import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePortfolio,
  fetchPortfolios,
} from "@/store/actions/portfolioAction.js";
import {
  deletePortfolioGroup,
  fetchPortfolioGroups,
} from "@/store/actions/portfolioGroupAction.js";
import { useNavigate } from "react-router-dom";
import {
  DotsHorizontalIcon,
  Pencil2Icon,
  TrashIcon,
} from "@radix-ui/react-icons";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown.jsx";
import { Button } from "@/components/ui/button.jsx";
import { dateConvert } from "@/shared/common/dateFormate.js";
import Alert from "@/components/common/Alert.jsx";
import DashboardMaster from "@/layout/dashboard/DashboardMaster.jsx";
import AddPortfolioModal from "@/components/modals/AddPortfolioModal";
import AddPortfolioModalGroup from "@/components/modals/AddPortfolioGroupModal";
import { DataTableNew } from "@/shared/common/table-components/tasks/components/data-table.jsx";
import DataTableColumnHeader from "@/shared/common/table-components/tasks/components/data-table-column-header.jsx";
import Card from "@/components/common/Card.jsx";
import IconAdd from "@/components/svg/IconAdd.jsx";

export const PortfolioMainContext = createContext();

const PortfolioMain = () => {
  const dispatch = useDispatch();
  const { portfolios, PortfolioGroup } = useSelector((state) => state);
  const [fetchData, setFetchData] = useState(null);
  const navigate = useNavigate();
  const [fetchDataGroup, setFetchDataGroup] = useState(null);
  const [fetchId, setFetchId] = useState(null);
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [showAlertDialogTow, setShowAlertDialogTow] = useState(false);
  const [allPortfolios, setAllPortfolios] = useState([]);
  const [allPortfoliosGroup, setAllPortfoliosGroup] = useState([]);
  const [addPortfolioModalOpen, setAddPortfolioModalOpen] = useState(false);
  const [addPortfolioGroupModalOpen, setAddPortfolioGroupModalOpen] =
    useState(false);
  const [isDeletePortfolio, setIsDeletePortfolio] = useState(null);
  const [isDeletePortfolioGroup, setIsDeletePortfolioGroup] = useState(null);

  useEffect(() => {
    if (
      addPortfolioModalOpen === false &&
      addPortfolioGroupModalOpen === false
    ) {
      setFetchData(null);
      setFetchId(null);
    }
  }, [addPortfolioGroupModalOpen, addPortfolioModalOpen]);

  const onChange = (filter) => {
    dispatch(fetchPortfolios(filter.page, filter.perPage));
  };

  // 6-5-24 new change
  useEffect(() => {
    dispatch(fetchPortfolioGroups());
  }, [dispatch]);

  // 6-5-24 old change
  // useEffect(() => {
  //     if (addPortfolioModalOpen) {
  //         dispatch(fetchPortfolioGroups());
  //     }
  // }, [addPortfolioModalOpen, dispatch]);

  // useEffect(() => {
  //     dispatch(fetchPortfolios(currentPage, perPage))
  // }, [currentPage, perPage, dispatch]);

  // useEffect(() => {
  //     // setTotalRecords(metaData)
  //     setTotalPages(Math.ceil(metaData.totalItems / metaData.pageSize))
  // }, [metaData]);

  useEffect(() => {
    if (portfolios?.length) {
      const formattedData = [];

      const portfoliosData = portfolios.map((portfolio) => ({
        _id: portfolio._id,
        portfolioGroup: { ...portfolio.portfolioGroup },
        portfolios: { ...portfolio.portfolio },
      }));

      formattedData.push(...portfoliosData);

      setAllPortfolios(portfoliosData);
    } else {
      setAllPortfolios([]);
    }
  }, [portfolios]);
  useEffect(() => {
    if (PortfolioGroup?.length) {
      const formattedData = [];

      const portfoliosData = PortfolioGroup.map((portfolio) => ({
        _id: portfolio._id,
        portfolioGroup: { ...portfolio.portfolioGroup },
        portfolios: { ...portfolio.portfolio },
      }));

      formattedData.push(...portfoliosData);

      setAllPortfoliosGroup(portfoliosData);
    } else {
      setAllPortfoliosGroup([]);
    }
  }, [PortfolioGroup]);

  const handleEditPortfolio = (data) => {
    setAddPortfolioModalOpen(true);
    setFetchData(data?.data);
    setFetchId(data?.data?.portfolios?.slug);
  };
  const handleEditPortfolioGroup = (data) => {
    setAddPortfolioGroupModalOpen(true);
    setFetchData(data?.data);
    setFetchId(data?.data?.portfolioGroup?.slug);
  };

  // const onPageChangeOne = (item) => {
  //     if (item?.portfolioGroup?.slug) {
  //         navigate('/portfolio-group/' + item.portfolioGroup.slug)
  //     }
  // }

  // const onPageChange = (item) => {
  //     if (item?.portfolioGroup?.slug && item?.portfolios?.slug) {
  //         // navigate('/portfolio/' + item?.portfolioGroup?.slug + "/" + item?.portfolios?.slug)
  //         navigate('/portfolio/' + item?.portfolios?.slug)
  //     } else {
  //         // navigate('/portfolio' + item?.portfolios?.slug)
  //         // navigate('/' + item?.portfolios?.slug)
  //         // dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'URL not found!' }))
  //     }
  // }

  const onPageChange = (item) => {
    if (item?.portfolioGroup?.slug && item?.portfolios?.slug) {
      navigate(
        "/portfolio/" +
          item?.portfolioGroup?.slug +
          "/" +
          item?.portfolios?.slug
      );
    } else {
      navigate("/portfolio" + "/no-portfolio-group/" + item?.portfolios?.slug);
      // dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'URL not found!' }))
    }
  };

  const onPageChangeOne = (item) => {
    if (item?.portfolioGroup?.slug) {
      navigate("/portfolio/" + item.portfolioGroup.slug);
    } else {
      // dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'URL not found!' }))
    }
  };

  const data =
    Array.isArray(allPortfolios) && allPortfolios.length > 0
      ? allPortfolios.map((item, index) => {
          return {
            data: item,
            portfolio_Group_Name:
              item?.portfolioGroup &&
              Object.keys(item?.portfolioGroup).length !== 0
                ? item?.portfolioGroup.name
                : "-",
            portfolio:
              item?.portfolios && Object.keys(item?.portfolios).length !== 0
                ? item?.portfolios?.name
                : "-",
            Portfolio_Group_Created_Date:
              item?.portfolioGroup &&
              Object.keys(item?.portfolioGroup).length !== 0
                ? dateConvert(item?.portfolioGroup.createdAt)
                : "-",
            Portfolio_Created_Date:
              item?.portfolios && Object.keys(item?.portfolios).length !== 0
                ? dateConvert(item?.portfolios.createdAt)
                : "-",
          };
        })
      : [];

  const columns = [
    {
      accessorKey: "portfolio",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Portfolio" />
      ),
      cell: ({ row }) => {
        return (
          <span
            className="flex items-center text-[#2487EE] hover:underline cursor-pointer"
            onClick={(e) => {
              onPageChange(row?.original?.data);
            }}
          >
            {row?.original?.portfolio ?? "-"}
          </span>
        );
      },
    },
    {
      accessorKey: "Portfolio CreatedAt",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Portfolio CreatedAt" />
      ),
      cell: ({ row }) => {
        return (
          <span className="flex items-center">
            {row?.original?.Portfolio_Created_Date}
          </span>
        );
      },
      enableSorting: true,
    },
    {
      accessorKey: "Portfolio Group",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Portfolio Group" />
      ),
      cell: ({ row }) => (
        <span
          className="flex items-center text-[#2487EE] hover:underline cursor-pointer"
          onClick={(e) => {
            onPageChangeOne(row?.original?.data);
          }}
        >
          {row?.original?.portfolio_Group_Name}
        </span>
      ),
      enableSorting: true,
    },
    {
      accessorKey: "PortfolioGroup createdAt",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title="PortfolioGroup createdAt"
        />
      ),
      cell: ({ row }) => (
        <span className="flex items-center">
          {row?.original?.Portfolio_Group_Created_Date}
        </span>
      ),
      enableSorting: true,
    },
    {
      id: "actions",
      enableHiding: false,
      header: <span className="flex items-center justify-end">Action</span>,
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-end">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="p-0">
                  <span className="sr-only">Open menu</span>
                  <DotsHorizontalIcon
                    strokeWidth={"2.2"}
                    className="h-5 w-5 text-textV2"
                  />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 ${
                    row?.original?.data?.portfolios &&
                    Object.keys(row?.original?.data?.portfolios).length !== 0
                      ? "flex"
                      : "hidden"
                  }`}
                  onClick={() => handleEditPortfolio(row?.original)}
                >
                  {" "}
                  <span className="w-5 h-5 bg-themeV2/10 flex items-center justify-center rounded-[4px]">
                    <Pencil2Icon
                      strokeWidth={"2.2"}
                      className="text-themeV2 w-4 h-4"
                    />{" "}
                  </span>{" "}
                  Edit Portfolio
                </DropdownMenuItem>
                <DropdownMenuItem
                  className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 ${
                    row?.original?.data?.portfolioGroup &&
                    Object.keys(row?.original?.data?.portfolioGroup).length !==
                      0
                      ? "flex"
                      : "hidden"
                  }`}
                  onClick={() => handleEditPortfolioGroup(row?.original)}
                >
                  {" "}
                  <span className="w-5 h-5 bg-themeV2/10 flex items-center justify-center rounded-[4px]">
                    <Pencil2Icon
                      strokeWidth={"2.2"}
                      className="text-themeV2 w-4 h-4"
                    />{" "}
                  </span>{" "}
                  Edit Portfolio Group
                </DropdownMenuItem>

                <DropdownMenuItem
                  className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 ${
                    row?.original?.data?.portfolios &&
                    Object.keys(row?.original?.data?.portfolios).length !== 0
                      ? "flex"
                      : "hidden"
                  }`}
                  onClick={() => showAlert(row?.original)}
                >
                  {" "}
                  <span className="w-5 h-5 bg-red/10 flex items-center justify-center rounded-[4px]">
                    <TrashIcon
                      strokeWidth={"2.2"}
                      className="text-red w-4 h-4"
                    />
                  </span>{" "}
                  Remove Portfolio
                </DropdownMenuItem>
                <DropdownMenuItem
                  className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 ${
                    row?.original?.data?.portfolioGroup &&
                    Object.keys(row?.original?.data?.portfolioGroup).length !==
                      0
                      ? "flex"
                      : "hidden"
                  }`}
                  onClick={() => showAlertTow(row?.original)}
                >
                  {" "}
                  <span className="w-5 h-5 bg-red/10 flex items-center justify-center rounded-[4px]">
                    <TrashIcon
                      strokeWidth={"2.2"}
                      className="text-red w-4 h-4"
                    />
                  </span>{" "}
                  Remove Portfolio Group
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
  ];

  const handleContinue = () => {
    let payloadData = { categories: [] };
    payloadData.categories.push(isDeletePortfolio.data?.portfolios?.slug);
    dispatch(deletePortfolio(isDeletePortfolio.data?.portfolios?.slug));
    setShowAlertDialog(false);
  };
  const handleContinueTow = () => {
    setShowAlertDialogTow(false);
    let payloadData = { categories: [] };
    payloadData.categories.push(isDeletePortfolioGroup.data.slug);
    const id =
      Object.keys(isDeletePortfolioGroup?.data?.portfolioGroup).length !== 0
        ? isDeletePortfolioGroup?.data?.portfolioGroup._id
        : "";
    dispatch(deletePortfolioGroup(id));
  };
  const handleCancel = () => {
    setShowAlertDialog(false);
    setShowAlertDialogTow(false);
  };

  const showAlert = (row) => {
    setIsDeletePortfolio(row);
    setShowAlertDialog(true);
  };
  const showAlertTow = (row) => {
    setIsDeletePortfolioGroup(row);
    setShowAlertDialogTow(true);
  };

  const rightHeaderButtons = [
    {
      icon: <IconAdd className="w-4 h-4 " />,
      text: "Add Portfolio",
      onClick: () => setAddPortfolioModalOpen(true),
      variant: "",
      additionalClass: "",
    },
    {
      icon: <IconAdd className="w-4 h-4 " />,
      text: "Add Portfolio Group",
      onClick: () => setAddPortfolioGroupModalOpen(true),
      variant: "",
      additionalClass: "",
    },
  ];

  return (
    <DashboardMaster title={"Portfolio"}>
      {/* <Portfolio data={data.length > 0 && data} allPortfolios={allPortfolios.length > 0 && allPortfolios} allPortfoliosGroup={allPortfoliosGroup} columns={columns} fetchData={fetchData} fetchId={fetchId} perPage={perPage} setPerPage={setPerPage} /> */}
      <Alert
        title="Are you absolutely sure?"
        description="This action cannot be undone. This will permanently delete your Portfolio data from our servers."
        handleClickContinue={handleContinue}
        handleClickCancel={handleCancel}
        showAlertDialog={showAlertDialog}
        setShowAlertDialog={setShowAlertDialog}
      />
      <Alert
        title="Are you absolutely sure?"
        description="This action cannot be undone. This will permanently delete your Portfolio Group data from our servers."
        handleClickContinue={handleContinueTow}
        handleClickCancel={handleCancel}
        showAlertDialog={showAlertDialogTow}
        setShowAlertDialog={setShowAlertDialogTow}
      />
      {/* old table  */}
      {/* <DataTable data={data} columns={columns} onChange={onChange} filterName={'portfolioName'} isPortfolio  isPortfolioGroup onPortfolio={() => setAddPortfolioModalOpen(true)} onPortfolioGroup={() => setAddPortfolioGroupModalOpen(true)}/> */}
      {/* new tasks table  */}
      <section className="p-2 md:px-5">
        <Card
          className={
            "p-2 md:px-5 bg-white shadow-[rgba(99, 99, 99, 0.2) 0px 2px 8px 0px]"
          }
        >
          <DataTableNew
            data={data}
            columns={columns}
            onChange={onChange}
            filterName={"portfolio"}
            isRightNew
            isOldHeaderHide={true}
            isRightHeaderButtons={true}
            rightHeaderButtons={rightHeaderButtons}
            isRightSearch={true}
          />
        </Card>
      </section>
      <AddPortfolioModal
        show={addPortfolioModalOpen}
        onHide={setAddPortfolioModalOpen}
        fetchData={fetchData}
        fetchId={fetchId}
        PortfolioGroup={PortfolioGroup}
        setFetchData={setFetchData}
        setFetchId={setFetchId}
        noValidate
      />
      <AddPortfolioModalGroup
        addPortfolioGroupModalOpen={addPortfolioGroupModalOpen}
        setAddPortfolioGroupModalOpen={setAddPortfolioGroupModalOpen}
        fetchData={fetchData}
        fetchId={fetchId}
        allPortfoliosGroup={allPortfoliosGroup}
        setFetchData={setFetchData}
        setFetchId={setFetchId}
      />
    </DashboardMaster>
  );
};

export default PortfolioMain;
