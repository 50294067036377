
import * as React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { PieChart } from 'echarts/charts';
import { GridComponent, TooltipComponent, TitleComponent, LegendComponent, ToolboxComponent } from 'echarts/components';
import { SVGRenderer } from 'echarts/renderers';
import DownloadChart from '../../shared/DownloadChart';
import { memo } from 'react';
import { useMemo } from 'react';

echarts.use([TitleComponent, TooltipComponent, GridComponent, LegendComponent, PieChart, ToolboxComponent, SVGRenderer]);


const dataS = [
  { label: 'Group A', value: 400, color: '#0088FE' },
  { label: 'Group B', value: 300, color: '#00C49F' },
  { label: 'Group C', value: 300, color: '#FFBB28' },
  { label: 'Group D', value: 200, color: '#FF8042' },
];

const sizing = {
  margin: { right: 5 },
  width: 300,
  height: 300,
  // legend: { hidden: false },
};
const TOTAL = dataS.map((item) => item.value).reduce((a, b) => a + b, 0);

const getArcLabel = (params) => {
  const percent = params.value / TOTAL;
  return `${(percent * 100).toFixed(0)}%`;
};


const PieChartCustom = (props) => {
  const { analyticReports, updateId, isAutoChart, isReports, index, refEl } = props;
  const [chartData, setChartData] = React.useState([])

  React.useEffect(() => {
    if (updateId === null && isReports === null) {
      setChartData([])
    } else {
      setChartData(analyticReports)
    }
  }, [analyticReports])




  let dateLabels = useMemo(() => chartData && chartData?.length >= 1 ? chartData[0].data?.dates : [], [chartData]);
  let chartValues = useMemo(() => chartData && chartData?.length >= 1 ? chartData[0].data?.value : [], [chartData]);
  const labels = useMemo(() => dateLabels ? dateLabels : '', [dateLabels]);
  let data
  if (isReports) {
    data = {
      labels: chartData && chartData?.labels?.length >= 1 ? chartData.labels : [],
      datasets: chartData && chartData?.datasets?.length >= 1 ? chartData?.datasets?.map(item => {
        return {
          label: item.label,
          data: item.data,
          backgroundColor: [
            'rgba(255, 99, 132, 0.5)',
            'rgba(54, 162, 235, 0.5)',
            'rgba(255, 206, 86, 0.5)',
            'rgba(75, 192, 192, 0.5)',
            'rgba(153, 102, 255, 0.5)',
            'rgba(255, 159, 64, 0.5)',
          ],
          borderWidth: 1,
        }
      }) : []
    }
  } else {
    data = {
      labels,
      datasets: [
        {
          label: labels,
          data: chartValues,
          fill: true,
          backgroundColor: [
            'rgba(255, 99, 132, 0.5)',
            'rgba(255, 159, 64, 0.5)',
            'rgba(255, 205, 86, 0.5)',
            'rgba(75, 192, 192, 0.5)',
            'rgba(54, 162, 235, 0.5)',
            'rgba(153, 102, 255, 0.5)',
            'rgba(255, 0, 223, 0.5)'
          ],
          borderColor: [
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(255, 0, 223)'
          ],
          borderWidth: 1
        },
      ],
    };
  }



  const chartRef = React.useRef(null);

  // Transforming the data
  const preparedData = data.datasets.flatMap(item =>
    item.data.map(value => ({
      label: item.label,
      value: parseFloat(value),
    }))
  );

  const transformedData = React.useMemo(() => data.datasets.map((dataset) => ({
    id: dataset.label,
    color: `hsl(${Math.floor(Math.random() * 360)}, 70%, 50%)`,
    data: dataset.data.map((value, index) => ({
      x: data.labels[index],
      y: parseInt(Number(value)),
    })),
  })), [data]);

  const transformedChartData = React.useMemo(() => transformedData.flatMap(entry =>
    entry.data.map(dataPoint => ({
      name: `${entry.id} - ${dataPoint.x}`,
      value: parseInt(Number(dataPoint.y))
    }))
  ), [transformedData]);

  const option = React.useMemo(() => ({
    tooltip: {
      trigger: 'item',
      axis: 'auto',
      axisPointer: { type: 'shadow' },
      displayMode: 'single',
      renderMode: 'auto',
      displayMode: 'single',
      renderMode: 'auto',
      // Confining tooltip to the body
      confine: true,
      align: 'center',
      backgroundColor: '#001F3D',
      appendToBody: true,
      textStyle: {
        fontSize: 12, // Adjust the font size as needed
        color: '#FFFFFF',
      }
    },
    // toolbox: {
    //   feature: {
    //     saveAsImage: { show: true, },
    //     type: 'png'
    //     // magicType: { show: true, type: ['line', 'bar'] },
    //     // dataView: { show: true, readOnly: false },
    //     // restore: { show: true }
    //   },
    //   top: 20
    // },
    legend: {
      orient: 'vertical',
      left: 'left',
      show: false
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: '80%',
        data: transformedChartData, // Use your transformedChartData here
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  }), [data, transformedChartData]);

  return (
    <>
    {data.datasets.length && data.labels.length ?
      <>
        <DownloadChart chartRef={refEl} chartData={data} chartId={"echarts-chart" + index} echarts={echarts} />
        <ReactEChartsCore
          className={"echarts-chart" + index  + ' ' + 'css-13aj3tc-MuiChartsSurface-root'}
          ref={refEl}
          echarts={echarts}
          style={{ width: '100%', height: '100%' }}
          option={option}
        />

      </> : <> <div>No Data Available</div>  </>}

  </>
  );
}

export default memo(PieChartCustom);

// =====================================================

// import * as React from 'react';

// import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
// import DownloadChart from '../../shared/DownloadChart';

// const dataS = [
//   { label: 'Group A', value: 400, color: '#0088FE' },
//   { label: 'Group B', value: 300, color: '#00C49F' },
//   { label: 'Group C', value: 300, color: '#FFBB28' },
//   { label: 'Group D', value: 200, color: '#FF8042' },
// ];

// const sizing = {
//   margin: { right: 5 },
//   width: 300,
//   height: 300,
//   // legend: { hidden: false },
// };
// const TOTAL = dataS.map((item) => item.value).reduce((a, b) => a + b, 0);

// const getArcLabel = (params) => {
//   const percent = params.value / TOTAL;
//   return `${(percent * 100).toFixed(0)}%`;
// };

// export default function PieChartCustom(props) {
//   const { analyticReports, updateId, isAutoChart, isReports, refEl } = props;
//   const [chartData, setChartData] = React.useState([])

//   React.useEffect(() => {
//     if (updateId === null && isReports === null) {
//       setChartData([])
//     } else {
//       setChartData(analyticReports)
//     }
//   }, [analyticReports])




//   let dateLabels = chartData && chartData?.length >= 1 ? chartData[0].data?.dates : [];
//   let chartValues = chartData && chartData?.length >= 1 ? chartData[0].data?.value : [];
//   const labels = dateLabels ? dateLabels : '';
//   let data
//   if (isReports) {
//     data = {
//       labels: chartData && chartData?.labels?.length >= 1 ? chartData.labels : [],
//       datasets: chartData && chartData?.datasets?.length >= 1 ? chartData?.datasets?.map(item => {
//         return {
//           label: item.label,
//           data: item.data,
//           backgroundColor: [
//             'rgba(255, 99, 132, 0.5)',
//             'rgba(54, 162, 235, 0.5)',
//             'rgba(255, 206, 86, 0.5)',
//             'rgba(75, 192, 192, 0.5)',
//             'rgba(153, 102, 255, 0.5)',
//             'rgba(255, 159, 64, 0.5)',
//           ],
//           borderWidth: 1,
//         }
//       }) : []
//     }
//   } else {
//     data = {
//       labels,
//       datasets: [
//         {
//           label: labels,
//           data: chartValues,
//           fill: true,
//           backgroundColor: [
//             'rgba(255, 99, 132, 0.5)',
//             'rgba(255, 159, 64, 0.5)',
//             'rgba(255, 205, 86, 0.5)',
//             'rgba(75, 192, 192, 0.5)',
//             'rgba(54, 162, 235, 0.5)',
//             'rgba(153, 102, 255, 0.5)',
//             'rgba(255, 0, 223, 0.5)'
//           ],
//           borderColor: [
//             'rgb(255, 99, 132)',
//             'rgb(255, 159, 64)',
//             'rgb(255, 205, 86)',
//             'rgb(75, 192, 192)',
//             'rgb(54, 162, 235)',
//             'rgb(153, 102, 255)',
//             'rgb(255, 0, 223)'
//           ],
//           borderWidth: 1
//         },
//       ],
//     };
//   }



//   const chartRef = React.useRef(null);

//   // Transforming the data
//   const preparedData = data.datasets.flatMap(item =>
//     item.data.map(value => ({
//       label: item.label,
//       value: parseFloat(value),
//     }))
//   );

//   return (
//     <>
//       {data.datasets.length && data.labels.length ?
//         <>
//           <DownloadChart chartData={data} chartRef={refEl} />
//           <PieChart
//             ref={refEl}
//             xAxis={[{ scaleType: 'point', data: data.labels }]}
//             series={[
//               {
//                 outerRadius: 140,
//                 // innerRadius: 30,
//                 // paddingAngle: 1,
//                 // cornerRadius: 1,
//                 // cx: 150,
//                 // cy: 150,
//                 arcLabelMinAngle: 100,
//                 // data: data.datasets[0]?.data.map((value, index) => ({
//                 //   label: data.datasets.map((item) => item.label)[0],
//                 //   // label: `${data.labels[index]}`,
//                 //   value: parseFloat(value),
//                 //   // color: data.datasets[0].backgroundColor[index],
//                 // })),
//                 data: preparedData,
//                 arcLabel: (params) => {
//                   const percent = params.value;
//                   // / data.datasets[0].data.reduce((a, b) => a + parseFloat(b), 0);
//                   return `${params.label} (${percent})%`;
//                 },
//               },
//             ]}
//             sx={{
//               [`& .${pieArcLabelClasses.root}`]: {
//                 fill: 'white',
//                 fontSize: 14,
//               },
//             }}
//             {...sizing}
//             width={300}
//             height={300}
//             legend={{ hidden: true }}
//           /></> : <div>No Data Available</div>}

//     </>
//   );
// }

// =====================================================

// <PieChart
//           ref={refEl}
//           series={[
//             {
//               outerRadius: 120,
//               data: dataS,
//               arcLabel: getArcLabel,
//             },
//           ]}
//           sx={{
//             [`& .${pieArcLabelClasses.root}`]: {
//               fill: 'white',
//               fontSize: 14,
//             },
//           }}
//           {...sizing}
//         />