import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFinanceExchanges } from '../../../../store/actions/FinanceAction';
import { fetchSubCategorie } from '../../../../store/actions/subClassAction';
import { fetchSubType } from '../../../../store/actions/subTypeAction';
import { financeSearch } from '../../../../store/actions/SearchFinancialAssetAction';
import { financialType, frequencyOptions, settlementDaysOptions } from '../../../../static/static';
import { addAssets } from '../../../../store/actions/AssetAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { Checkbox } from "@/components/ui/checkbox"
import { Oval } from 'react-loader-spinner';
import { Textarea } from '../../../../components/ui/textarea';
import { assetByFetchIssuer } from '../../../../store/actions/issuerAction';
import { getCurveByCountryCode } from '../../../../store/actions/CurveAction';
import { getTenorByCurve } from '../../../../store/actions/tenorAction';
import { DatePickerCustomInput } from '../../../../components/ui/DatePickerCustomInput';
import Loading from '../../../../shared/common/Loading';
import PaginatedSelect from '../../../../components/common/PaginatedSelect';
import useDebounce from '../../issuer/tables/useDebounce';
import countryDateLocales from '../../../../helpers/country-date-formate';

const AssetModel = (props) => {
    const dispatch = useDispatch()
    const { setAssetsData } = props;
    const { classData, countries, subClassData, types, subTypes, financeSearchData, curve, tenor, financeExchange } = useSelector(state => state)

    // all states declare here
    const [error, setError] = useState({});
    const [financialOp, setFinancialOp] = useState({ value: 'all', label: 'All' })
    const checkBoxRef = useRef();
    const [suggestedAssetNames, setSuggestedAssetNames] = useState(null);
    const [suggestedAssetId, setSuggestedAssetId] = useState(null);
    const [allFinanceSearchData, setAllFinanceSearchData] = useState([])
    const [allFinanceExchangeData, setAllFinanceExchangeData] = useState([])
    const [allFinanceExchangeId, setAllFinanceExchangeId] = useState(null);
    const [activeCurve, setActiveCurve] = useState(null);
    const [activeTenor, setActiveTenor] = useState(null);
    const [frequency, setFrequency] = useState(null);
    const [settlementDays, setSettlementDays] = useState(null);
    const [date, setDate] = useState(null)
    const [maturityDate, setMaturityDate] = useState(null)
    const [allCurves, setAllCurves] = useState([])
    const [allTenor, setAllTenor] = useState([])
    const [isLoad, setIsLoad] = useState(false)
    const [isShowTag, setIsShowTag] = useState(true)
    const [isDisabledIn, setIsDisabledIn] = useState(false)
    const [isCheck, setIsCheck] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        class: null,
        assetName: "",
        description: "",
        country: null,
        subClass: null,
        type: null,
        subType: null,
        Symbol: '',
        exchangeCountry: null,
        issuer: '',
        faceAmount: 0,
        couponRate: 0,

        // Additional fields
        issueDate: null,
        maturityDate: null,
        settlementDate: null,
        currency: null,
        isin: null,
        coupon: null,
        callable: {
            value: "No",
            label: 'No'
        },
        nextCallDate: null,
        bondType: null,
        yieldToMaturity: null,
        calendar: null,
        faceValue: null
    });

    // ISSUER Search HANDEL
    const [isLoadIssuer, setIsLoadIssuer] = useState(false);
    const [isDisabledIssuer, setIsDisabledIssuer] = useState(false);
    const [issuerData, setIssuerData] = useState([]);
    const [issuerSearch, setIssuerSearch] = useState('');
    const [selectedIssuer, setSelectedIssuer] = useState(false);
    const [selectedNameData, setSelectedNameData] = useState(null);
    const [selectedPublicAsset, setSelectedPublicAsset] = useState(null)


    // all useEffects here
    const debouncedIssuerSearch = useDebounce(issuerSearch, 500);
    useEffect(() => {
        if (issuerSearch.length >= 1 && !selectedIssuer) {
            setIsLoadIssuer(true)
            dispatch(assetByFetchIssuer(setIsLoadIssuer, setIssuerData, issuerSearch))
        } else {
            setIssuerData([])
        }
    }, [debouncedIssuerSearch]);


    // ISSUER Search HANDEL END
    useEffect(() => {
        if (props.show === false) {
            setSuggestedAssetNames(null)
            setSuggestedAssetId(null)
            setIsCheck(false)
            setIsLoad(false)
            setIsDisabledIn(false)
            setIsShowTag(true)
            setFormData({
                class: null,
                assetName: "",
                description: "",
                country: null,
                subClass: null,
                type: null,
                subType: null,
                Symbol: '',
                exchangeCountry: null,
                issuer: '',
                faceAmount: 0,
                couponRate: 0,

                // Additional fields
                issueDate: null,
                maturityDate: null,
                settlementDate: null,
                currency: null,
                isin: null,
                coupon: null,
                callable: null,
                nextCallDate: null,
                bondType: null,
                yieldToMaturity: null,
                calendar: null,
                faceValue: null
            })
            setIsLoadIssuer(false)
            setIsDisabledIssuer(false)
            setSelectedIssuer(false)
            setIssuerData([])
            setIssuerSearch('');
            setFinancialOp(null);
            setAllCurves(null);
            setAllTenor(null);
            setAllFinanceExchangeData(null)
            setActiveCurve(null);
            setActiveTenor(null);
            setDate(null);
            setFrequency(null);
            setSettlementDays(null);
            setMaturityDate(null)
            setError({})
        } else {
            // dispatch(fetchTypes('all'));
        }
    }, [props.show]);

    // financial option change then reselect country,curve,tenor,date,frequency,settlementDays again
    useEffect(() => {
        setFormData({
            ...formData,
            country: null,
            exchangeCountry: null,

        })

        setActiveCurve(null);
        setActiveTenor(null);
        setDate(null);
        setFrequency(null);
        setSettlementDays(null);
        setAllFinanceExchangeId(null)

    }, [financialOp]);


    // new curve code start
    useEffect(() => {

        setAllCurves(curve?.length > 0 && curve)
    }, [curve])

    // curve api here
    // useEffect(() => {
    //     if (financialOp?.value === "bond" && formData?.country !== null) {

    //         const selectedCountry = countries?.length > 0 && countries?.filter((country) => country?._id === formData?.country?.value)
    //         const countryId = selectedCountry?.length > 0 ? selectedCountry[0]?._id : ""

    //         dispatch(getCurveByCountryCode(countryId))
    //     }
    // }, [financialOp, formData?.country])
    //curve code end

    // for maturity date change 
    useEffect(() => {
        if (activeTenor !== null) {
            const dateObj = new Date()
            const maturityDate = new Date(dateObj.setMonth(dateObj.getMonth() + activeTenor?.month))
            setMaturityDate(maturityDate)
        }
    }, [activeTenor])

    // tenor code start
    useEffect(() => {
        setAllTenor(tenor?.records?.length > 0 && tenor?.records)
    }, [tenor])

    useEffect(() => {

        if (activeCurve !== null) {
            if (financialOp?.value === "bond") {
                dispatch(getTenorByCurve(activeCurve?.value))
            }
        }
    }, [activeCurve?.value])
    // tenor code end

    useEffect(() => {
        // dispatch(fetchTypes('all'));
        // dispatch(fetchSubTypes());
        // setType(types)
        // setSubType(subTypes)
    }, [dispatch])

    useEffect(() => {
        if (formData.country?.value && isCheck === true && financialOp?.value !== 'bond') {
            // && financialOp?.value !== "bond"
            dispatch(fetchFinanceExchanges(formData.country?.value))
        }

    }, [formData.country])

    useEffect(() => {
        setAllFinanceExchangeData(financeExchange?.records)
    }, [financeExchange?.records])

    useEffect(() => {
        if (formData.class !== null) {
            dispatch(fetchSubCategorie(formData?.class?.value, 'all'))
        }
    }, [formData.class, dispatch])
    useEffect(() => {
        if (formData.type !== null) {
            dispatch(fetchSubType(formData?.type?.value, 'all'))

        }
    }, [formData.type, dispatch])


    const debouncedText = useDebounce(formData?.assetName, 500);

    useEffect(() => {
        if (isCheck && formData.assetName.length >= 2 && formData.country?.value) {
            // && financialOp?.value !== "bond"
            function removeContentInRoundBracketsAndAfter(inputString) {
                // Use a regular expression to match content within round brackets, everything after an opening parenthesis, and everything after a period or comma
                const result = inputString.replace(/\([^)]*\)|\(.*/g, '').replace(/[.,].*/g, '');

                return result;
            }

            const data = {
                name: removeContentInRoundBracketsAndAfter(formData.assetName?.trim()),
                country: formData.country?.value,
                instrument: financialOp?.value === "bond" ? "BOND" : financialOp?.value,
                exchange: allFinanceExchangeId !== null ?
                    allFinanceExchangeId?.value !== null ? allFinanceExchangeId?.value : ''
                    : ''
            }
            setIsLoad(true)
            dispatch(financeSearch(data));
            setSelectedNameData(null)
        }
    }, [debouncedText])

    useEffect(() => {
        if (financeSearchData) {
            setAllFinanceSearchData(financeSearchData)
        }
    }, [financeSearchData])

    useEffect(() => {
        if (allFinanceSearchData && allFinanceSearchData.length >= 1) {
            // Transform the response to include both name and symbol
            const suggestions = allFinanceSearchData.map(item => {

                return ({
                    label: `${item.name ? item.name : ''} ${financialOp?.value !== "bond" ? `(${item.symbol ? item.symbol : ''})` : ''}  | ${item.exchange?.name ? item.exchange.name : ''}`,
                    value: item._id,
                    name: item.name ? item.name : '',
                    country: item.country
                })
            });
            setSuggestedAssetNames(suggestions);
            setIsLoad(false)
        } else {
            setSuggestedAssetNames(null);
            setIsLoad(false)
        }
    }, [allFinanceSearchData]);

    useEffect(() => {
        if (formData?.country !== null) {
            const currentCountry = countries?.find((country) => country?._id === formData?.country?.value) || {}
            if (Object.keys(currentCountry)?.length > 0) {
                setFormData({
                    ...formData,
                    calendar: generateCalenderData(currentCountry?.alpha2Code)
                })
            }
        }
    }, [formData.country])

    // bond after select name all data dynamically set here

    useEffect(() => {
        if (selectedNameData !== null && allFinanceSearchData?.length > 0 && formData?.assetName?.length > 1 && formData.country) {

            const filterData = allFinanceSearchData?.find((search) => search?._id === selectedNameData?.value);

            if (Object.keys(filterData).length > 0) {
                setSelectedPublicAsset(filterData)
                const currentCountry = countries?.find((country) => country?._id === filterData?.country)
                if (Object.keys(currentCountry).length > 0) {

                    setFormData({
                        ...formData,
                        currency: filterData?.currency,
                        isin: filterData?.isin ?? null,
                        coupon: filterData?.coupon ?? null,
                        callable: filterData?.callable === "Yes" ? { label: "Yes", value: "Yes" } : { label: "No", value: "No" },
                        nextCallDate: filterData?.callable === "Yes" && filterData?.nextCallDate ? filterData?.nextCallDate : null,
                        bondType: filterData?.classificationData?.bondType ?? null,
                        yieldToMaturity: filterData?.yieldToMaturity ?? null,
                        issueDate: filterData?.issueData?.issueDate ?? null,
                        maturityDate: filterData.maturityDate ?? null,
                        settlementDate: formData?.settlementDate ?? null,
                        // set other dynamic fields
                    })
                }
            }
        }
    }, [selectedNameData]);

    const generateCalenderData = (countryAlpha2Code) => {
        const country = countryDateLocales.find((country) => country.alpha2Code === countryAlpha2Code);
        if (Object.keys(country || {}).length > 0) {
            return country
        } else {
            return {}
        }

    }
    // all options prepare here

    const financialExchangeOptions = allFinanceExchangeData && allFinanceExchangeData.length >= 1 ? [{ value: null, label: 'All' }, ...allFinanceExchangeData.map((item) => {
        return { value: item._id, label: item.name }
    })] : []
    const financialOptions = financialType && financialType.length >= 1 ? financialType.map((item) => {
        return { value: item.id, label: item.label }
    }) : []
    const countriesOptions = countries && countries.length >= 1 ? countries.map((item) => {
        return { value: item._id, label: item.countryName }
    }) : []
    const categoryOptions = classData && classData?.records?.length >= 1 ? classData?.records?.map((item) => {
        return { value: item.id, label: item.name }
    }) : []

    const subCategoriesOptions = subClassData && Array.isArray(subClassData.subClasses) ? subClassData?.subClasses.map((item) => {
        return { value: item._id, label: item.name }
    }) : []

    const typeOptions = types && types?.records?.length >= 1 ? types?.records?.map((item) => {
        return { value: item.id, label: item.name }
    }) : []

    const subTypesOptions = subTypes && Array.isArray(subTypes.subTypes) ? subTypes?.subTypes.map((item) => {
        return { value: item._id, label: item.name }
    }) : []

    // curve options 

    const curveOptions = allCurves?.length > 0 && allCurves?.map((curve) => {
        return {
            value: curve?._id,
            label: curve?.name,
            slug: curve?.slug
        }
    })

    //tenor options

    const tenorOptions = allTenor?.length > 0 && allTenor?.map((tenor) => {
        return {
            value: tenor?._id,
            label: tenor?.maturity,
            month: tenor?.month
        }
    });

    // all onChange here

    const onChangeFinancialExchangeOption = (event) => {
        setAllFinanceExchangeId(event)
    }
    const onChangeFinancialOption = (event) => {
        setFinancialOp(event)
    }
    const onChangeCountriesOption = (event) => {
        setFormData({ ...formData, country: event })
        setError({})
    }
    const onChangeExchangeCountriesOption = (event) => {
        setFormData({ ...formData, exchangeCountry: event })
        setError({})
    }
    const onChangeClassOption = (event) => {
        setFormData({ ...formData, class: event })
        setError({})
    }
    const onChangeCallableOption = (event) => {
        setFormData({ ...formData, callable: event })
        setError({})
    }
    const onChangeSubClassOption = (event) => {
        setFormData({ ...formData, subClass: event })
        setError({})
    }
    const onChangeCalenderOption = (event) => {
        setFormData({ ...formData, calendar: event })
        setError({})
    }
    const onChangeTypeOption = (event) => {
        setFormData({ ...formData, type: event })
        setError({})
    }
    const onChangeSubTypeOption = (event) => {
        setFormData({ ...formData, subType: event })
        setError({})
    }
    // onChange curve 
    const onChangeCurveOption = (e) => {
        // set curve value to active curve
        setActiveCurve(e)
    }

    // onChange tenor 
    const onChangeTenorOption = (e) => {
        // set tenor value to active tenor
        setActiveTenor(e)
    }
    // onChange settlementDays 
    const onChangeSettlementDaysOption = (e) => {
        // set tenor value to active tenor
        setSettlementDays(e)
    }
    //  onChange date 
    const handleDateChange = (date) => {
        setDate(date)
    }
    //  onChange Maturity date 
    const handleMaturityDateChange = (date) => {
        setMaturityDate(date)
    }
    // onChange frequency 
    const onChangeFrequencyOption = (e) => {
        // set curve value to active curve
        setFrequency(e)
    }

    const onChangeHandel = (e) => {
        const { name, value } = e.target;
        // setFormData({ ...formData, [name]: value });
        if (name === "assetName") {
            setFormData({ ...formData, [name]: value });
            setIsDisabledIn(false);
            setIsShowTag(true)
        }
        if (name === "faceAmount" || name === "couponRate") {
            setFormData({ ...formData, [name]: Number(value) });
        } else {
            setFormData({ ...formData, [name]: value });
        }
        // name === 'assetName' && formData?.assetName?.length >= 2 && setIsCheck(true)
        setError({})
    }
    const handleOnChange = (event) => {
        setIsCheck(!isCheck);
        if (isCheck === false) {
            setFormData({ ...formData, assetName: '', Symbol: '', exchangeCountry: null })
            setIsDisabledIn(false)
            setIsLoad(false)
        }
    }




    // formData validation here
    // const handleValidation = () => {
    //     let errors = {};
    //     let isValid = true;

    //     if (formData.assetName === '') {
    //         errors['assetName'] = 'Please enter Name';
    //         isValid = false;
    //     }
    //     if (!isCheck && formData.Symbol === '') {
    //         errors['Symbol'] = 'Please enter Symbol Name';
    //         isValid = false;
    //     }
    //     // if (!isCheck && formData.Symbol.includes(' ')) {
    //     //     errors['Symbol'] = 'Please remove space after save now';
    //     //     isValid = false;
    //     // }
    //     if (formData.class === null) {
    //         errors['class'] = 'Please select class';
    //         isValid = false;
    //     }
    //     if (formData.country === null) {
    //         errors['country'] = 'Please select country';
    //         isValid = false;
    //     }
    //     // if (formData.description === '') {
    //     //     errors['description'] = 'Please enter description';
    //     //     isValid = false;
    //     // }
    //     if (formData.subClass === null) {
    //         errors['subClass'] = 'Please select subClass';
    //         isValid = false;
    //     }
    //     if (formData.type === null) {
    //         errors['type'] = 'Please enter Type';
    //         isValid = false;
    //     }
    //     if (formData.subType === null) {
    //         errors['subType'] = 'Please select subType';
    //         isValid = false;
    //     }

    //     if (financialOp?.value === "bond" && (formData.faceAmount === 0 || formData.faceAmount < 0)) {
    //         errors['faceAmount'] = 'Please enter face amount';
    //         isValid = false;
    //     }

    //     if (financialOp?.value === "bond" && (formData.couponRate === 0 || formData.couponRate < 0)) {
    //         errors['couponRate'] = 'Please enter coupon rate';
    //         isValid = false;
    //     }

    //     if (financialOp?.value === "bond" && settlementDays === null) {
    //         errors['settlementDays'] = 'Please select settlement days';
    //         isValid = false;
    //     }
    //     if (financialOp?.value === "bond" && frequency === null) {
    //         errors['frequency'] = 'Please select frequency';
    //         isValid = false;
    //     }
    //     if (financialOp?.value === "bond" && maturityDate === null) {
    //         errors['maturityDate'] = 'Please select maturity date';
    //         isValid = false;
    //     }

    //     setError(errors);
    //     return isValid;
    // };
    const handleValidation = () => {
        let errors = {};
        let isValid = true;

        // Validate common fields
        if (formData.assetName === '') {
            errors['assetName'] = 'Please enter Name';
            isValid = false;
        }

        if (!isCheck && formData.Symbol === "") {
            errors['Symbol'] = 'Please enter Symbol Name';
            isValid = false;
        }
        if (formData.class === null) {
            errors['class'] = 'Please select class';
            isValid = false;
        }
        if (formData.country === null) {
            errors['country'] = 'Please select country';
            isValid = false;
        }
        if (formData.subClass === null) {
            errors['subClass'] = 'Please select subClass';
            isValid = false;
        }
        if (formData.type === null) {
            errors['type'] = 'Please enter Type';
            isValid = false;
        }
        if (formData.subType === null) {
            errors['subType'] = 'Please select subType';
            isValid = false;
        }

        // Validate bond-specific fields if financialOp is "bond"
        if (financialOp?.value === "bond") {
            if (isCheck && formData.Symbol === "") {
                errors['Symbol'] = 'Please enter Symbol Name';
                isValid = false;
            }
            if (formData.issuer === '') {
                errors['issuer'] = 'Please enter issuer name';
                isValid = false;
            }
            if (formData.faceValue === 0 || formData.faceValue < 0) {
                errors['faceValue'] = 'Please enter a valid face value';
                isValid = false;
            }

            if (formData.coupon === 0 || formData.coupon < 0) {
                errors['coupon'] = 'Please enter a valid coupon';
                isValid = false;
            }

            if (!formData.settlementDate) {
                errors['settlementDate'] = 'Please select settlement date';
                isValid = false;
            }

            if (!formData.maturityDate) {
                errors['maturityDate'] = 'Please select maturity date';
                isValid = false;
            }

            if (!formData.issueDate) {
                errors['issueDate'] = 'Please select issue date';
                isValid = false;
            }

            if (formData.callable === null) {
                errors['callable'] = 'Please specify if the bond is callable';
                isValid = false;
            }

            if (formData.callable?.value === "Yes" && !formData.nextCallDate) {
                errors['nextCallDate'] = 'Please select the next call date';
                isValid = false;
            }

            if (formData.bondType === null) {
                errors['bondType'] = 'Please select bond type';
                isValid = false;
            }

            if (!formData.yieldToMaturity || formData.yieldToMaturity < 0) {
                errors['yieldToMaturity'] = 'Please enter a valid yield to maturity';
                isValid = false;
            }

            if (!formData.calendar?.alpha2Code) {
                errors['calendar'] = 'Please select a calendar';
                isValid = false;
            }

            if (!formData.currency) {
                errors['currency'] = 'Please select a currency';
                isValid = false;
            }

            if (!formData.isin) {
                errors['isin'] = 'Please enter a  ISIN ';
                isValid = false;
            }
        }

        // Set errors and return the overall validation result
        setError(errors);
        return isValid;
    };

    console.log(selectedPublicAsset)
    //onSave button click
    const onSave = (e) => {
        if (handleValidation()) {
            setIsLoading(true)
            const countryId = financialOp?.value === "bond" ? formData.country.value : isCheck ? formData.exchangeCountry : formData.country.value

            const data = financialOp?.value === "bond" ? {
                // asset: null,
                // operationType: 'add',
                class: formData.class.value,
                subClass: formData.subClass.value,
                type: formData.type.value,
                subType: formData.subType.value,
                isPublicAsset: isCheck,
                publicAsset: selectedPublicAsset !== null ? selectedPublicAsset?._id : null, // get public asset id here
                assetName: suggestedAssetId !== null ? formData.assetName : formData.assetName,
                description: formData.description,
                country: countryId,
                issuer: formData.issuer !== '' ? formData.issuer : null,
                symbol: formData.Symbol?.toLocaleUpperCase().replace(/\s/g, ''),
                currency: formData.currency,
                coupon: formData.coupon,
                issueDate: new Date(formData.issueDate).toLocaleDateString("en-ca"),
                faceValue: formData.faceValue,
                callable: formData.callable.value,
                nextCallDate: new Date(formData.nextCallDate).toLocaleDateString("en-ca"),
                bondType: formData.bondType,
                calendar: formData.calendar?.alpha2Code,
                maturityDate: new Date(formData.maturityDate).toLocaleDateString("en-ca"),
                settlementDate: new Date(formData.settlementDate).toLocaleDateString("en-ca"),
                // frequency: frequency?.value,
                // settlementDays: settlementDays?.value,
                // curve: activeCurve?.value,
                // tenor: activeTenor?.value,

                // other details
                // financialInstrument: financialOp?.value,
                // yieldToMaturity: formData.yieldToMaturity,
                // isin: formData.isin,
            } : isCheck ?
                {
                    class: formData.class.value,
                    subClass: formData.subClass.value,
                    type: formData.type.value,
                    subType: formData.subType.value,
                    isPublicAsset: isCheck,
                    financialInstrument: financialOp?.value || null,
                    publicAsset: suggestedAssetId !== null ? suggestedAssetId : null,
                    assetName: suggestedAssetId !== null ? formData.assetName : formData.assetName,
                    description: formData.description,
                    country: countryId,
                    issuer: formData.issuer !== '' ? formData.issuer : null,
                    symbol: formData.Symbol?.toLocaleUpperCase().replace(/\s/g, '')
                }
                : {
                    // asset: null,
                    // operationType: 'add',
                    class: formData.class.value,
                    subClass: formData.subClass.value,
                    type: formData.type.value,
                    subType: formData.subType.value,
                    isPublicAsset: isCheck,
                    publicAsset: suggestedAssetId !== null ? suggestedAssetId : null,
                    assetName: suggestedAssetId !== null ? formData.assetName : formData.assetName,
                    description: formData.description,
                    country: countryId,
                    issuer: formData.issuer !== '' ? formData.issuer : null,
                    symbol: formData.Symbol?.toLocaleUpperCase().replace(/\s/g, '')
                }
            dispatch(addAssets(data, setAssetsData, 1, null)).then((res) => {

                // props.onHide();
                setIsLoading(false)
                props.onHide();

                setSuggestedAssetNames(null)
                setSuggestedAssetId(null)
                setIsCheck(false)
                setFormData({
                    class: null,
                    assetName: "",
                    description: "",
                    country: null,
                    subClass: null,
                    type: null,
                    subType: null,
                    Symbol: '',
                    exchangeCountry: null,
                    issuer: '',
                    faceAmount: 0,
                    couponRate: 0,

                    // Additional fields
                    issueDate: null,
                    maturityDate: null,
                    settlementDate: null,
                    currency: null,
                    isin: null,
                    coupon: null,
                    callable: null,
                    nextCallDate: null,
                    bondType: null,
                    yieldToMaturity: null,
                    calendar: null,
                    faceValue: null
                })


            })


        }

    }


    const onSelectIssuer = (value) => {
        setFormData({ ...formData, issuer: value._id })
        setIssuerSearch(value.name)
        setIsDisabledIssuer(true)
        setSelectedIssuer(true)
        setIssuerData([])
    }
    const removeIssuer = () => {
        setFormData({ ...formData, issuer: '' })
        setIssuerSearch('')
        setIsDisabledIssuer(false)
        setIsLoadIssuer(false)
        setSelectedIssuer(false)
        setIssuerData([])

    }

    const getSuggestedData = (e, data) => {
        const name = data.label;

        // Using regular expression to extract text inside parentheses
        const matches = name.match(/\((.*?)\)/);

        // Check if there is a match and extract the value
        const result = matches ? matches[1] : '';

        setFormData({ ...formData, assetName: data.name, Symbol: result, exchangeCountry: data.country })
        setSuggestedAssetId(data.value)

        setSelectedNameData(data)
        // setIsCheck(false)
        setIsShowTag(false)
        setIsDisabledIn(true)
        setIsLoad(false)
    }

    const removeName = () => {
        setFormData({ ...formData, assetName: '', Symbol: '', exchangeCountry: null })
        setIsDisabledIn(false)
        setSuggestedAssetId(null)
        setIsShowTag(true)

    }


    const onChangeBondHandler = (e) => {
        const { name, value } = e.target;
        if (name === "faceValue") {
            setFormData({ ...formData, [name]: Number(value) });
        } else {
            setFormData((prevFormData) => { return { ...prevFormData, [name]: value } });
        }
    };

    const trueFalseOptions = [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
    ];

    console.log(formData)

    return (
        <>
            <Dialog open={props.show} onOpenChange={props.onHide}>
                <DialogContent className={`sm:max-w-[80%] max-h-[95%] gap-0 p-0 overflow-y-auto `}>
                    <DialogHeader className="p-5 border-b border-borderV1">
                        <DialogTitle className="text-start">{'Add Asset'}</DialogTitle>
                    </DialogHeader>
                    <div className="dialog-body p-5">
                        {/* public asset checkbox here */}
                        {/* <div className="grid grid-cols-2 mb-6">
                            <div className="form-group grid grid-cols-12 items-center gap-1">
                                <div className="label-wrapper col-span-4 ">
                                    <Label htmlFor="existingPortfolio" className="text-textV1 font-500 leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 className=">
                                        Public Asset:
                                    </Label>
                                </div>
                                <div className="field-wrapper col-span-8">
                                    <Checkbox checked={isCheck} ref={checkBoxRef} onCheckedChange={handleOnChange} className="w-4 h-4 rounded-[6px] bg-white border-black" />
                                </div>
                            </div>
                        </div> */}
                        {/* financial infrastructure input here */}
                        <div className="grid grid-cols-2 gap-14">
                            <div className="flex gap-6 flex-col">
                                <div className={` ${isCheck ? "form-group grid grid-cols-12 items-center gap-1" : "hidden"}`}>
                                    <div className="label-wrapper col-span-4 ">
                                        <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Financial Instrument:
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Select
                                            onValueChange={(e) => onChangeFinancialOption(JSON.parse(e))}
                                            defaultValue={financialOp}
                                        >
                                            <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                                <SelectValue
                                                    className='text-textV1'
                                                    placeholder="Financial  Instrument"
                                                >
                                                    {financialOp !== null ? financialOp?.label : "Financial  Instrument"}
                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {
                                                        financialOptions && financialOptions.length > 0 ? financialOptions.map((item, index) => {
                                                            return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                        }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                    </div>
                                </div>

                                {/* country input here */}
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="label-wrapper col-span-4 ">
                                        <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Country:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        {/* changes here  */}
                                        {/* 
                                            financialOp?.value === "bond" ? <CreatableSelect options={countriesOptions} onChange={onChangeCountriesOption} classNamePrefix={"react_select"} className='countrySelect' />
                                            :
                                        */}
                                        <Select
                                            onValueChange={(e) => onChangeCountriesOption(JSON.parse(e))}
                                            defaultValue={countriesOptions[0]}
                                            disabled={financialOp?.value === "bond" ? false : isCheck ? true : false}
                                            value={formData.country || null}
                                        // financialOp?.value === "bond" ? false :
                                        >
                                            <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                                <SelectValue
                                                    className='text-textV1'
                                                    placeholder="Select Country"
                                                    // financialOp?.value === "bond" ? false :
                                                    disabled={financialOp?.value === "bond" ? false : isCheck ? true : false}
                                                >
                                                    {/* {"Select Country"} */}
                                                    {financialOp?.value === "bond" ? formData.country !== null ? formData.country?.label : "Select Country" : isCheck ? "Select Country" : formData.country !== null ? formData.country?.label : "Select Country"}
                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {
                                                        countriesOptions && countriesOptions.length > 0 ? countriesOptions.map((item, index) => {
                                                            return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                        }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                        <div className={error['country'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['country'] ? error['country'] : null}</div>
                                    </div>
                                </div>
                                {/* exChange country input here */}
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="label-wrapper col-span-4 ">
                                        <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Exchange Country:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Select
                                            onValueChange={(e) => onChangeExchangeCountriesOption(JSON.parse(e))}
                                            defaultValue={countriesOptions[0]}
                                            disabled={financialOp?.value === "bond" ? true : !isCheck ? true : false}
                                            value={formData.country || null}
                                        // financialOp?.value === "bond" ? true :
                                        >
                                            <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                                <SelectValue
                                                    className='text-textV1'
                                                    placeholder="Select Exchange Country"
                                                    disabled={financialOp?.value === "bond" ? true : !isCheck ? true : false}

                                                // financialOp?.value === "bond" ? true :
                                                >
                                                    {!isCheck ? "Select Exchange Country" : formData.country !== null ? formData.country?.label : "Select Exchange Country"}

                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {
                                                        countriesOptions && countriesOptions.length > 0 ? countriesOptions.map((item, index) => {
                                                            return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                        }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                        <div className={error['country'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['country'] ? error['country'] : null}</div>
                                    </div>
                                </div>
                                {/* exchange input here */}
                                <div className={` ${isCheck ? "form-group grid grid-cols-12 items-center gap-1 " : "hidden"}`}>
                                    <div className="label-wrapper col-span-4 ">
                                        <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Exchange:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Select
                                            onValueChange={(e) => onChangeFinancialExchangeOption(JSON.parse(e))}
                                            defaultValue={allFinanceExchangeId}
                                            disabled={financialOp?.value === "bond" ? true : formData.country === null}
                                            value={allFinanceExchangeId || null}
                                        // financialOp?.value === "bond" ? true :
                                        >
                                            <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                                <SelectValue
                                                    className='text-textV1'
                                                    placeholder="Select Exchange"
                                                    disabled={financialOp?.value === "bond" ? true : formData.country === null}
                                                // financialOp?.value === "bond" ? true :
                                                >
                                                    {allFinanceExchangeId !== null ? allFinanceExchangeId?.label : "Select Exchange"}

                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {
                                                        financialExchangeOptions && financialExchangeOptions.length > 0 ? financialExchangeOptions.map((item, index) => {
                                                            return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                        }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                    </div>
                                </div>
                                {/* name input here */}
                                {/* financialOp?.value === "bond" && */}
                                {isCheck && <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className=" label-wrapper col-span-4">
                                        <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Name:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <div className='relative'>
                                            <Input className={`w-full h-10 text-[13px] rounded-[12px] form-control shadow-none  ${error['assetName'] && 'is-invalid'}`} autoComplete={'off'} name='assetName' placeholder='Name' onChange={(e) => onChangeHandel(e)} id="inputEmail3" type="text" value={formData.assetName} disabled={financialOp?.value !== "bond" ? formData.exchangeCountry === null ? true : false : formData.country === null ? true : false} />
                                            <div className={error['assetName'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['assetName'] ? error['assetName'] : null}</div>
                                            {isLoad && <div class="absolute" style={{ top: '8px', right: '5px', width: '15px', height: '15px' }} role="status">
                                                <Oval
                                                    visible={true}
                                                    height="15"
                                                    width="15"
                                                    color="#4fa94d"
                                                    ariaLabel="oval-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                />
                                            </div>}
                                            {!isLoad && isDisabledIn && <div class="absolute" style={{ top: '8px', right: '5px', width: '15px', height: '15px' }} role="status" onClick={() => removeName()}>
                                                <span className=""><FontAwesomeIcon className='text-red' icon={faXmark} /></span>
                                            </div>}
                                        </div>

                                        <div className='w-100'>
                                            {isShowTag && isCheck && formData.assetName.length >= 2 && suggestedAssetNames?.length && (<ul className='suggested_list shadow rounded-md'>
                                                {suggestedAssetNames.map((item) => {
                                                    return (
                                                        <li className='text-[12px] font-semibold' key={item.label} onClick={(e) => getSuggestedData(e, item)}>{item.label}</li>
                                                    )
                                                })}
                                            </ul>)}
                                        </div>

                                    </div>
                                </div>}
                                {((financialOp?.value === "bond" && isCheck === true)) && <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="label-wrapper col-span-4">
                                        <Label htmlFor="isin" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            ISIN:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <div className='relative'>
                                            <Input readOnly={true} className={`w-full h-10 text-[13px] rounded-[12px] form-control shadow-none ${error['isin'] && 'is-invalid'}`} autoComplete={'off'} name='isin' placeholder='ISIN' onChange={(e) => onChangeBondHandler(e)} id="isin" type="text" value={formData.isin} />
                                            <div className={error['isin'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['isin'] ? error['isin'] : null}</div>
                                        </div>
                                    </div>
                                </div>}
                                {/* symbol input here */}
                                {/* financialOp?.value === "bond" && */}
                                {isCheck && <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className=" label-wrapper col-span-4">
                                        <Label htmlFor="username" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Symbol:{!isCheck && <span style={{ color: 'red' }}>*</span>}
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Input className={`w-full h-10 text-[13px] rounded-[12px] ${error['Symbol'] && 'is-invalid'}`} placeholder='Symbol' autoComplete={'off'} name='Symbol' onChange={(e) => onChangeHandel(e)} id="inputEmail3" type="text" value={formData.Symbol.toLocaleUpperCase().replace(/\s/g, '')} />

                                        <div className={error['Symbol'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['Symbol'] ? error['Symbol'] : null}</div>
                                    </div>
                                </div>}
                                {/* financialOp?.value === "bond" && */}
                                {isCheck && <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="label-wrapper col-span-4">
                                        <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Issuer:
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <div className='relative'>
                                            <Input className={`w-full h-10 text-[13px] rounded-[12px] form-control shadow-none`} autoComplete={'off'} disabled={isDisabledIssuer} name='issuer' placeholder='Issuer' onChange={(e) => {
                                                setIssuerSearch(e.target.value);
                                                // setIsDisabledIn(false);
                                                // setIsShowTag(true)
                                            }} id="inputEmail3" type="text" value={issuerSearch} />
                                            <div className={error['issuer'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['issuer'] ? error['issuer'] : null}</div>
                                            {isLoadIssuer && <div class="absolute" style={{ top: '8px', right: '5px', width: '15px', height: '15px' }} role="status">
                                                <Oval
                                                    visible={true}
                                                    height="15"
                                                    width="15"
                                                    color="#4fa94d"
                                                    ariaLabel="oval-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                />
                                            </div>}
                                            {!isLoadIssuer && isDisabledIssuer && <div class="absolute" style={{ top: '8px', right: '5px', width: '15px', height: '15px' }} role="status" onClick={() => removeIssuer()}>
                                                <span className=""><FontAwesomeIcon className='text-red' icon={faXmark} /></span>
                                            </div>}
                                        </div>

                                        <div className='w-100'>
                                            {issuerData?.records?.length ? (<ul className='suggested_list shadow'>
                                                {issuerData?.records?.map((item) => {
                                                    return (
                                                        <li className='text-[12px] font-semibold' key={item.issuerId} onClick={(e) => onSelectIssuer(item)}>{item.name}</li>
                                                    )
                                                })}
                                            </ul>) : ''}
                                        </div>

                                    </div>
                                </div>}

                                {/* curve input here */}
                                {false && financialOp?.value === "bond" && <div className={` ${isCheck ? "form-group grid grid-cols-12 items-center gap-1 " : "hidden"}`}>
                                    <div className="label-wrapper col-span-4 ">
                                        <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Curve:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Select
                                            onValueChange={(e) => onChangeCurveOption(JSON.parse(e))}
                                            defaultValue={activeCurve !== null && activeCurve?.value}
                                            disabled={financialOp?.value === "bond" ? false : formData.country === null}
                                        >
                                            <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                                <SelectValue
                                                    className='text-textV1'
                                                    placeholder="Select Exchange"
                                                    disabled={financialOp?.value === "bond" ? false : formData.country === null}
                                                >
                                                    {activeCurve !== null ? activeCurve?.label : "Select Curve"}

                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {
                                                        curveOptions && curveOptions.length > 0 ? curveOptions.map((item, index) => {
                                                            return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                        }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                    </div>
                                </div>}
                                {/* Issue Date here */}

                                {false && financialOp?.value === "bond" && <div className={` ${isCheck ? "form-group grid grid-cols-12 items-center gap-1 " : "hidden"}`}>
                                    <div className=" label-wrapper col-span-4">
                                        <Label htmlFor="issueDate" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Issue Date:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <DatePickerCustomInput
                                            onDateSelect={(date) => handleDateChange(date)}
                                            placeholderText='YYYY-MM-DD'
                                            minDate={new Date("1900-01-01")}
                                            value={date}
                                        />
                                    </div>
                                </div>}
                                {/* tenor input here */}
                                {false && financialOp?.value === "bond" && <div className={` ${isCheck ? "form-group grid grid-cols-12 items-center gap-1 " : "hidden"}`}>
                                    <div className=" label-wrapper col-span-4">
                                        <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Maturity In Tenure:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Select
                                            onValueChange={(e) => onChangeTenorOption(JSON.parse(e))}
                                            defaultValue={activeTenor !== null && activeTenor?.value}
                                            disabled={activeCurve === null ? true : false}
                                        >
                                            <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                                <SelectValue
                                                    className='text-textV1'
                                                    placeholder="Select Maturity"
                                                    disabled={activeCurve !== null ? false : true}
                                                >
                                                    {activeTenor !== null ? activeTenor?.label : "Select Maturity"}

                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {
                                                        tenorOptions && tenorOptions.length > 0 ? tenorOptions.map((item, index) => {
                                                            return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                        }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                    </div>
                                </div>}
                                {/* maturity in date */}
                                {
                                    false && financialOp?.value === "bond" && <div className={` ${isCheck ? "form-group grid grid-cols-12 items-center gap-1 " : "hidden"}`}>
                                        <div className=" label-wrapper col-span-4">
                                            <Label htmlFor="issueDate" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                                Maturity In Date:<span style={{ color: 'red' }}>*</span>
                                            </Label>
                                        </div>
                                        <div className="field-wrapper col-span-8">
                                            <DatePickerCustomInput
                                                onDateSelect={(date) => handleMaturityDateChange(date)}
                                                placeholderText='YYYY-MM-DD'
                                                minDate={new Date("1900-01-01")}
                                                value={maturityDate}
                                                className="overflow-y-auto"
                                            />
                                            <div className={error['maturityDate'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['maturityDate'] ? error['maturityDate'] : null}</div>
                                        </div>
                                    </div>
                                }


                                {/* name input here */}
                                {/* || financialOp?.value !== "bond" || financialOp === null */}
                                {((isCheck === false)) && <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className=" label-wrapper col-span-4">
                                        <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Name:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <div className='relative'>
                                            <Input className={`w-full h-10 text-[13px] rounded-[12px] form-control shadow-none  ${error['assetName'] && 'is-invalid'}`} autoComplete={'off'} disabled={formData.country === null || isDisabledIn} name='assetName' placeholder='Name' onChange={(e) => onChangeHandel(e)} id="inputEmail3" type="text" value={formData.assetName} />
                                            <div className={error['assetName'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['assetName'] ? error['assetName'] : null}</div>
                                            {isLoad && <div class="absolute" style={{ top: '8px', right: '5px', width: '15px', height: '15px' }} role="status">
                                                <Oval
                                                    visible={true}
                                                    height="15"
                                                    width="15"
                                                    color="#4fa94d"
                                                    ariaLabel="oval-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                />
                                            </div>}
                                            {!isLoad && isDisabledIn && <div class="absolute" style={{ top: '8px', right: '5px', width: '15px', height: '15px' }} role="status" onClick={() => removeName()}>
                                                <span className=""><FontAwesomeIcon className='text-red' icon={faXmark} /></span>
                                            </div>}
                                        </div>

                                        <div className='w-100'>
                                            {isShowTag && isCheck && formData.assetName.length >= 2 && suggestedAssetNames?.length && (<ul className='suggested_list shadow'>
                                                {suggestedAssetNames.map((item) => {
                                                    return (
                                                        <li className='text-[12px] font-semibold' key={item.label} onClick={(e) => getSuggestedData(e, item)}>{item.label}</li>
                                                    )
                                                })}
                                            </ul>)}
                                        </div>

                                    </div>
                                </div>}
                                {/* symbol input here */}
                                {/* || financialOp?.value !== "bond" || financialOp === null  */}
                                {((isCheck === false)) && <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className=" label-wrapper col-span-4">
                                        <Label htmlFor="username" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Symbol:{!isCheck && <span style={{ color: 'red' }}>*</span>}
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Input className={`w-full h-10 text-[13px] rounded-[12px] ${error['Symbol'] && 'is-invalid'}`} placeholder='Symbol' autoComplete={'off'} disabled={formData.country === null || isCheck ? true : false} name='Symbol' onChange={(e) => onChangeHandel(e)} id="inputEmail3" type="text" value={formData.Symbol.toLocaleUpperCase().replace(/\s/g, '')} />

                                        <div className={error['Symbol'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['Symbol'] ? error['Symbol'] : null}</div>
                                    </div>
                                </div>}
                                {/* financialOp?.value !== "bond" || financialOp === null */}
                                {((isCheck === false)) && <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="label-wrapper col-span-4">
                                        <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Issuer:
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <div className='relative'>
                                            <Input className={`w-full h-10 text-[13px] rounded-[12px] form-control shadow-none`} autoComplete={'off'} disabled={isDisabledIssuer} name='issuer' placeholder='Issuer' onChange={(e) => setIssuerSearch(e.target.value)} id="inputEmail3" type="text" value={issuerSearch} />
                                            {/* <div className={error['assetName'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['assetName'] ? error['assetName'] : null}</div> */}
                                            {isLoadIssuer && <div class="absolute" style={{ top: '8px', right: '5px', width: '15px', height: '15px' }} role="status">
                                                <Oval
                                                    visible={true}
                                                    height="15"
                                                    width="15"
                                                    color="#4fa94d"
                                                    ariaLabel="oval-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                />
                                            </div>}
                                            {!isLoadIssuer && isDisabledIssuer && <div class="absolute" style={{ top: '8px', right: '5px', width: '15px', height: '15px' }} role="status" onClick={() => removeIssuer()}>
                                                <span className=""><FontAwesomeIcon className='text-red' icon={faXmark} /></span>
                                            </div>}
                                        </div>

                                        <div className='w-100'>
                                            {issuerData?.records?.length ? (<ul className='suggested_list shadow'>
                                                {issuerData?.records?.map((item) => {
                                                    return (
                                                        <li className='text-[12px] font-semibold' key={item.issuerId} onClick={(e) => onSelectIssuer(item)}>{item.name}</li>
                                                    )
                                                })}
                                            </ul>) : ''}
                                        </div>

                                    </div>
                                </div>}


                                {/* all new bond fields */}
                                {
                                    financialOp?.value === "bond" && isCheck === true && <>
                                        <div className="form-group grid grid-cols-12 items-center gap-1">
                                            <div className="label-wrapper col-span-4">
                                                <Label htmlFor="currency" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                                    Currency:<span style={{ color: 'red' }}>*</span>
                                                </Label>
                                            </div>
                                            <div className="field-wrapper col-span-8">
                                                <div className='relative'>
                                                    <Input className={`w-full h-10 text-[13px] rounded-[12px] form-control shadow-none ${error['currency'] && 'is-invalid'}`} autoComplete={'off'} disabled={formData.country === null} name='currency' placeholder='Currency' onChange={(e) => onChangeBondHandler(e)} id="currency" type="text" value={formData.currency} />
                                                    <div className={error['currency'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['currency'] ? error['currency'] : null}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                                {((financialOp?.value === "bond" && isCheck === true)) && <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="label-wrapper col-span-4">
                                        <Label htmlFor="coupon" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Coupon:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <div className='relative'>
                                            <Input className={`w-full h-10 text-[13px] rounded-[12px] form-control shadow-none ${error['coupon'] && 'is-invalid'}`} autoComplete={'off'} disabled={formData.country === null} name='coupon' placeholder='Coupon' onChange={(e) => onChangeBondHandler(e)} id="coupon" type="text" value={formData.coupon} />
                                            <div className={error['coupon'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['coupon'] ? error['coupon'] : null}</div>
                                        </div>
                                    </div>
                                </div>}
                                {((financialOp?.value === "bond" && isCheck === true)) && <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="label-wrapper col-span-4">
                                        <Label htmlFor="maturityDate" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Maturity Date:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <div className='relative'>

                                            <DatePickerCustomInput
                                                onDateSelect={(date) => setFormData({
                                                    ...formData,
                                                    maturityDate: date
                                                })}
                                                className={`w-full h-10 text-[13px] rounded-[12px] form-control shadow-none ${error['maturityDate'] && 'is-invalid'}`}
                                                disabled={formData.country === null}
                                                placeholderText={'Maturity Date'}
                                                mainClassName={'form-control shadow-none'}
                                                selectedFromDate={formData.maturityDate}
                                                minDate={new Date("1900-01-01")}
                                                value={formData.maturityDate}
                                                dateModelClassNames="!w-full"
                                            />

                                            <div className={error['maturityDate'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['maturityDate'] ? error['maturityDate'] : null}</div>
                                        </div>
                                    </div>
                                </div>}
                                {((financialOp?.value === "bond" && isCheck === true)) && <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="label-wrapper col-span-4">
                                        <Label htmlFor="callable" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Callable:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        {/* <div className='relative'>
                                            <Input className={`w-full h-10 text-[13px] rounded-[12px] form-control shadow-none ${error['callable'] && 'is-invalid'}`} autoComplete={'off'} disabled={isDisabledIn} name='callable' placeholder='Callable' onChange={(e) => onChangeBondHandler(e)} id="callable" type="text" value={formData.callable} />
                                            <div className={error['callable'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['callable'] ? error['callable'] : null}</div>
                                        </div> */}
                                        <Select
                                            onValueChange={(e) => onChangeCallableOption(JSON.parse(e))}
                                            defaultValue={trueFalseOptions[1]}
                                            disabled={formData.country === null}
                                            value={formData.callable !== null ? formData.callable : null}

                                        >
                                            <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                                <SelectValue
                                                    className='text-textV1'
                                                    placeholder={formData.callable !== null ? formData.callable?.label : "Select Callable"}
                                                    disabled={formData.country === null}
                                                >
                                                    {formData.callable !== null ? formData.callable?.label : "Select Callable"}

                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {
                                                        trueFalseOptions && trueFalseOptions.length > 0 ? trueFalseOptions.map((item, index) => {
                                                            return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                        }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                    </div>
                                </div>}
                                {((financialOp?.value === "bond" && isCheck === true)) && <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="label-wrapper col-span-4">
                                        <Label htmlFor="nextCallDate" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Next Call Date:
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <div className={`relative ${(formData.country === null || formData.callable?.value === "No") ? "cursor-not-allowed" : ""}`}>

                                            <DatePickerCustomInput
                                                onDateSelect={(date) => setFormData({
                                                    ...formData,
                                                    nextCallDate: date
                                                })}
                                                className={`w-full h-10  text-[13px] rounded-[12px] form-control shadow-none ${error['nextCallDate'] && 'is-invalid'}`}
                                                disabled={formData.country === null || formData.callable?.value === "No"}
                                                inputDisabled={formData.country === null || formData.callable?.value === "No"}
                                                placeholderText={'Issue Date'}
                                                mainClassName={'form-control shadow-none'}
                                                selectedFromDate={formData.nextCallDate}
                                                minDate={new Date("1900-01-01")}
                                                value={formData.nextCallDate}
                                                dateModelClassNames="!w-full"
                                            />

                                            <div className={error['nextCallDate'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['nextCallDate'] ? error['nextCallDate'] : null}</div>
                                        </div>
                                    </div>
                                </div>}


                                {/* all new bond end */}
                            </div>
                            {/* class input here */}
                            <div className="flex gap-6 flex-col">



                                {/* Frequency select here */}
                                {false && financialOp?.value === "bond" && <div className={` ${isCheck ? "form-group grid grid-cols-12 items-center gap-1 " : "hidden"}`}>
                                    <div className=" label-wrapper col-span-4">
                                        <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Frequency:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Select
                                            onValueChange={(e) => onChangeFrequencyOption(JSON.parse(e))}
                                            defaultValue={frequency !== null && frequency?.value}
                                            disabled={financialOp?.value === "bond" ? false : true}
                                        >
                                            <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                                <SelectValue
                                                    className='text-textV1'
                                                    placeholder="Select Frequency"
                                                    disabled={financialOp?.value === "bond" ? false : true}
                                                >
                                                    {frequency !== null ? frequency?.label : "Select Frequency"}

                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {
                                                        frequencyOptions && frequencyOptions.length > 0 ? frequencyOptions.map((item, index) => {
                                                            return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                        }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                        <div className={error['frequency'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['frequency'] ? error['frequency'] : null}</div>
                                    </div>

                                </div>}

                                {/* settlementDays input here */}

                                {false && financialOp?.value === "bond" && <div className={` ${isCheck ? "form-group grid grid-cols-12 items-center gap-1 " : "hidden"}`}>
                                    <div className=" label-wrapper col-span-4">
                                        <Label htmlFor="settlementDays" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Settlement Days:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <div>
                                            <Select
                                                onValueChange={(e) => onChangeSettlementDaysOption(JSON.parse(e))}
                                                defaultValue={settlementDays !== null && settlementDays?.value}

                                                disabled={financialOp?.value === "bond" ? false : true}
                                            >
                                                <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]" >
                                                    <SelectValue
                                                        className='text-textV1'
                                                        placeholder="Select Settlement Days"
                                                        disabled={financialOp?.value === "bond" ? false : true}
                                                    >
                                                        {settlementDays !== null ? settlementDays?.label : "Select Settlement Days"}

                                                    </SelectValue>
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectGroup>
                                                        {
                                                            settlementDaysOptions && settlementDaysOptions.length > 0 ? settlementDaysOptions.map((item, index) => {
                                                                return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                            }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                        }
                                                    </SelectGroup>
                                                </SelectContent>
                                            </Select>
                                            <div className={error['settlementDays'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['settlementDays'] ? error['settlementDays'] : null}</div>
                                        </div>
                                    </div>
                                </div>}


                                {/* coupon rate input here */}
                                {false && isCheck && financialOp?.value === "bond" && <div>
                                    <div className="form-group grid grid-cols-12 items-center gap-1">
                                        <div className="label-wrapper col-span-4">
                                            <Label htmlFor="username" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                                Coupon Rate: <span style={{ color: 'red' }}>*</span>
                                                {/* <span style={{ color: 'red' }}>*</span> */}
                                            </Label>
                                        </div>
                                        <div className="field-wrapper col-span-8">
                                            <Input
                                                type="number"
                                                className={`form-control shadow-none fs-13 px-2 ${error['couponRate'] && 'is-invalid'}`} disabled={formData.country === null} name='couponRate' onChange={(e) => onChangeHandel(e)} id="basic-form-textarea" placeholder=" Coupon Rate.." value={formData.couponRate} />
                                            {/* <div className={error['description'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['description'] ? error['description'] : null}</div> */}
                                            <div className={error['couponRate'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['couponRate'] ? error['couponRate'] : null}</div>
                                        </div>
                                    </div>
                                </div>}
                                {/* face amount input here */}
                                {false && isCheck && financialOp?.value === "bond" && <div>
                                    <div className="form-group grid grid-cols-12 items-center gap-1">
                                        <div className="label-wrapper col-span-4">
                                            <Label htmlFor="username" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                                Face Amount: <span style={{ color: 'red' }}>*</span>
                                                {/* <span style={{ color: 'red' }}>*</span> */}
                                            </Label>
                                        </div>
                                        <div className="field-wrapper col-span-8">
                                            <Input
                                                type="number"
                                                className={`form-control shadow-none fs-13 px-2 ${error['faceAmount'] && 'is-invalid'}`} disabled={formData.country === null} name='faceAmount' onChange={(e) => onChangeHandel(e)} id="basic-form-textarea" rows="3" placeholder=" Face Amount.." value={formData.faceAmount} />
                                            {/* <div className={error['description'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['description'] ? error['description'] : null}</div> */}
                                            <div className={error['faceAmount'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['faceAmount'] ? error['faceAmount'] : null}</div>
                                        </div>
                                    </div>
                                </div>}

                                {((financialOp?.value === "bond" && isCheck === true)) && <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="label-wrapper col-span-4">
                                        <Label htmlFor="bondType" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Bond Type:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <div className='relative'>
                                            <Input className={`w-full h-10 text-[13px] rounded-[12px] form-control shadow-none ${error['bondType'] && 'is-invalid'}`} autoComplete={'off'} disabled={formData.country === null} name='bondType' placeholder='Bond Type' onChange={(e) => onChangeBondHandler(e)} id="bondType" type="text" value={formData.bondType} />
                                            <div className={error['bondType'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['bondType'] ? error['bondType'] : null}</div>
                                        </div>
                                    </div>
                                </div>}
                                {((financialOp?.value === "bond" && isCheck === true)) && <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="label-wrapper col-span-4">
                                        <Label htmlFor="issueDate" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Issue Date:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <div className='relative'>
                                            {/* <Input className={`w-full h-10 text-[13px] rounded-[12px] form-control shadow-none ${error['issueDate'] && 'is-invalid'}`} autoComplete={'off'} disabled={isDisabledIn} name='issueDate' placeholder='Issue Date' onChange={(e) => onChangeBondHandler(e)} id="issueDate" type="date" value={formData.issueDate} /> */}
                                            {/* onDateSelect, className, disabled, placeholderText, mainClassName, selectedFromDate, minDate, value, upDateData, readOnly */}
                                            <DatePickerCustomInput
                                                onDateSelect={(date) => setFormData({
                                                    ...formData,
                                                    issueDate: date
                                                })}
                                                className={`w-full h-10 text-[13px] rounded-[12px] form-control shadow-none ${error['issueDate'] && 'is-invalid'}`}
                                                disabled={formData.country === null}
                                                placeholderText={'Issue Date'}
                                                mainClassName={'form-control shadow-none'}
                                                selectedFromDate={formData.issueDate}
                                                minDate={new Date("1900-01-01")}
                                                value={formData.issueDate}
                                                inputDisabled={formData.country === null}
                                                dateModelClassNames="!w-full"
                                            />
                                            <div className={error['issueDate'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['issueDate'] ? error['issueDate'] : null}</div>
                                        </div>
                                    </div>
                                </div>}
                                {((financialOp?.value === "bond" && isCheck === true)) && <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="label-wrapper col-span-4">
                                        <Label htmlFor="yieldToMaturity" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Yield To Maturity:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <div className='relative'>
                                            <Input className={`w-full h-10 text-[13px] rounded-[12px] form-control shadow-none ${error['yieldToMaturity'] && 'is-invalid'}`} autoComplete={'off'} disabled={formData.country === null} name='yieldToMaturity' placeholder='Yield To Maturity' onChange={(e) => onChangeBondHandler(e)} id="yieldToMaturity" type="text" value={formData.yieldToMaturity} />
                                            <div className={error['yieldToMaturity'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['yieldToMaturity'] ? error['yieldToMaturity'] : null}</div>
                                        </div>
                                    </div>
                                </div>}
                                {((financialOp?.value === "bond" && isCheck === true)) && <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="label-wrapper col-span-4">
                                        <Label htmlFor="calendar" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Calendar:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <div className='relative'>
                                            {/* <Input className={`w-full h-10 text-[13px] rounded-[12px] form-control shadow-none ${error['calendar'] && 'is-invalid'}`} autoComplete={'off'} disabled={isDisabledIn} name='calendar' placeholder='Calendar' onChange={(e) => onChangeBondHandler(e)} id="calendar" type="text" value={formData.calendar || formData.currency} /> */}

                                            <Select
                                                onValueChange={(e) => onChangeCalenderOption(JSON.parse(e))}
                                                defaultValue={countryDateLocales[0]}
                                                value={formData.calendar !== null ? formData.calendar : null}
                                            >
                                                <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                                    <SelectValue
                                                        className='text-textV1'
                                                        placeholder="Select Calender"
                                                    >
                                                        {formData.calendar !== null ? `${formData.calendar?.label} (${formData.calendar?.value})` : "Select Calender"}

                                                    </SelectValue>
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectGroup>
                                                        {
                                                            countryDateLocales && countryDateLocales.length > 0 ? countryDateLocales.map((item, index) => {
                                                                return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{`${item?.label} (${item?.value})`}</SelectItem>
                                                            }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                        }
                                                    </SelectGroup>
                                                </SelectContent>
                                            </Select>

                                            <div className={error['calendar'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['calendar'] ? error['calendar'] : null}</div>
                                        </div>
                                    </div>
                                </div>}
                                {((financialOp?.value === "bond" && isCheck === true)) && <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="label-wrapper col-span-4">
                                        <Label htmlFor="settlementDate" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Settlement Date:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <div className='relative'>
                                            {/* <Input className={`w-full h-10 text-[13px] rounded-[12px] form-control shadow-none ${error['settlementDate'] && 'is-invalid'}`} autoComplete={'off'} disabled={isDisabledIn} name='settlementDate' placeholder='Settlement Date' onChange={(e) => onChangeBondHandler(e)} id="settlementDate" type="date" value={formData.settlementDate} /> */}

                                            <DatePickerCustomInput
                                                onDateSelect={(date) => setFormData({
                                                    ...formData,
                                                    settlementDate: date
                                                })}
                                                className={`w-full h-10 text-[13px] rounded-[12px] form-control shadow-none ${error['settlementDate'] && 'is-invalid'}`}
                                                disabled={formData.country === null}
                                                placeholderText={'Settlement Date'}
                                                mainClassName={'form-control shadow-none'}
                                                selectedFromDate={formData.settlementDate}
                                                minDate={new Date("1900-01-01")}
                                                value={formData.settlementDate}
                                                dateModelClassNames="!w-full"
                                            />


                                            <div className={error['settlementDate'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['settlementDate'] ? error['settlementDate'] : null}</div>
                                        </div>
                                    </div>
                                </div>}
                                {((financialOp?.value === "bond" && isCheck === true)) && <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="label-wrapper col-span-4">
                                        <Label htmlFor="faceValue" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Face Value:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <div className='relative'>
                                            <Input defaultValue={100} className={`w-full h-10 text-[13px] rounded-[12px] form-control shadow-none ${error['faceValue'] && 'is-invalid'}`} autoComplete={'off'} disabled={formData.country === null} name='faceValue' placeholder='Face Value' onChange={(e) => onChangeBondHandler(e)} id="faceValue" type="numbers" value={formData.faceValue} />
                                            <div className={error['faceValue'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['faceValue'] ? error['faceValue'] : null}</div>
                                        </div>
                                    </div>
                                </div>}

                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="label-wrapper col-span-4">
                                        <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Class:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Select
                                            onValueChange={(e) => onChangeClassOption(JSON.parse(e))}
                                            defaultValue={categoryOptions[0]}
                                            disabled={formData.country === null}
                                        >
                                            <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                                <SelectValue
                                                    className='text-textV1'
                                                    placeholder="Select Class"
                                                    disabled={formData.country === null}
                                                >
                                                    {formData.class !== null ? formData.class?.label : "Select Class"}

                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {
                                                        categoryOptions && categoryOptions.length > 0 ? categoryOptions.map((item, index) => {
                                                            return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                        }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                        <div className={error['class'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['class'] ? error['class'] : null}</div>
                                    </div>
                                </div>
                                {/* sub class input here */}
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="label-wrapper col-span-4">
                                        <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Sub Class:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Select
                                            onValueChange={(e) => onChangeSubClassOption(JSON.parse(e))}
                                            defaultValue={subCategoriesOptions && subCategoriesOptions[0]}
                                            disabled={formData.class === null && true}
                                        >
                                            <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                                <SelectValue
                                                    className='text-textV1'
                                                    placeholder="Select Sub Class"
                                                    disabled={formData.class === null && true}
                                                >
                                                    {formData.subClass !== null ? formData.subClass?.label : "Select Sub Class"}

                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {
                                                        subCategoriesOptions && subCategoriesOptions.length > 0 ? subCategoriesOptions.map((item, index) => {
                                                            return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                        }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                        <div className={error['subClass'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['subClass'] ? error['subClass'] : null}</div>
                                    </div>
                                </div>
                                {/* type input here */}
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="label-wrapper col-span-4">
                                        <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Type:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Select
                                            onValueChange={(e) => onChangeTypeOption(JSON.parse(e))}
                                            defaultValue={typeOptions[0]}
                                            disabled={!formData.country}
                                        >
                                            <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                                <SelectValue
                                                    className='text-textV1'
                                                    placeholder="Select Type"
                                                    disabled={!formData.country}
                                                >
                                                    {formData.type !== null ? formData.type?.label : "Select Type"}

                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {
                                                        typeOptions && typeOptions.length > 0 ? typeOptions.map((item, index) => {
                                                            return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                        }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                        <div className={error['type'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['type'] ? error['type'] : null}</div>
                                    </div>
                                </div>
                                {/* sub type input here */}
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="label-wrapper col-span-4">
                                        <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Sub Type:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Select
                                            onValueChange={(e) => onChangeSubTypeOption(JSON.parse(e))}
                                            defaultValue={subTypesOptions && subTypesOptions[0]}
                                            disabled={formData.type === null && true}
                                        >
                                            <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                                <SelectValue
                                                    className='text-textV1'
                                                    placeholder="Select Sub Type"
                                                    disabled={formData.type === null && true}
                                                >
                                                    {formData.subType !== null ? formData.subType?.label : "Select Sub Type"}

                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {
                                                        subTypesOptions && subTypesOptions.length > 0 ? subTypesOptions.map((item, index) => {
                                                            return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                        }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                        <div className={error['subType'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['subType'] ? error['subType'] : null}</div>
                                    </div>
                                </div>
                                {/* description input here */}
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="label-wrapper col-span-4">
                                        <Label htmlFor="username" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Description:
                                            {/* <span style={{ color: 'red' }}>*</span> */}
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Textarea
                                            className={`form-control shadow-none fs-13 px-2 ${error['description'] && 'is-invalid'}`} disabled={formData.country === null} name='description' onChange={(e) => onChangeHandel(e)} id="basic-form-textarea" rows="3" placeholder="Description" value={formData.description} />
                                        <div className={error['description'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['description'] ? error['description'] : null}</div>
                                    </div>
                                </div>

                                {/* {
                                    financialOp?.value === "bond" && isCheck === true && <PaginatedSelect 
                                        apiEndpoint={"/assets"}
                                        onSelectionChange={(date) => console.log(date)}
                                        placeholder={"Enter a Value"}
                                        initialData={[]}
                                    />
                                } */}

                            </div>
                        </div>
                    </div>

                    {isLoading && <div className='absolute top-[50%] left-[50%] '>
                        <Loading />
                    </div>}

                    <DialogFooter className={"!justify-center !gap-2 p-5"}>
                        <Button type="submit" size="sm" className="min-w-[100px]" onClick={onSave}>Save</Button>
                        <Button type="submit" className="min-w-[100px]" size="sm" variant={"outline"} onClick={props.onHide}>Cancel</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AssetModel
