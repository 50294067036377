import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { AssentContext } from './Reporting';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { deleteReports, fetchReports, getAllReports } from '../../../store/actions/reportingAction';
import moment from 'moment-js';
import { addTost } from '../../../store/actions/toastAction';
import { toastifyType } from '../../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import downloadPDF from '../../../shared/common/fetchPdfData';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ReportingModel from '../../../shared/common/reporting/model/ReportingModel';
import VerticalExposureTable from '../../../shared/common/reporting/tables/VerticalExposureTable';
import HorizontalExposureTable from '../../../shared/common/reporting/tables/HorizontalExposureTable';
import BarChartCustom from '../../../components/chart/BarChart';
import PieChartCustom from '../../../components/chart/PieChart';
import StackedAreaChart from '../../../components/chart/AreaChart';
import BasicScatter from '../../../components/chart/ScatterChart';
import { ToDatePicker } from '../../../components/ui/toDatePicker';
import { Button } from '../../../components/ui/button';
// import { FromDatePicker } from '../../../components/ui/fromDatePicker';
import IconAdd from '../../../components/svg/IconAdd';
import Loading from '../../../shared/common/Loading';
import { Card } from '../../../components/ui/Card';
import BasicLineChart from '../../../components/chart/LineCharts';
import { Trash, DownloadIcon } from 'lucide-react'
import { fetchAssetsReportingDetails } from '@/store/actions/AssetDetailsAction';
import { Progress } from "@/components/ui/progress"
import Alert from '../../../components/common/Alert';
import { FromDatePicker } from '../../../components/ui/DatePickerNew';
import DoughnutChart from '../../../components/chart/DoughnutChart';
import RadarCharts from '../../../components/chart/RadarChart';
import { Margin, usePDF } from 'react-to-pdf';
import { DatePickerCustomInput } from '../../../components/ui/DatePickerCustomInput';

const ReportingMain = () => {
    const { updateId, portfolios, allAnalytics } = useContext(AssentContext);
    // const getData = localStorage.getItem('reports')
    const { reportsData, assetsDetails } = useSelector(state => state)
    const dispatch = useDispatch();
    const [isLoadings, setIsLoadings] = useState(false)

    const lineChartRef = useRef();
    const barChartRef = useRef();
    const bubbleChartRef = useRef();
    const doughnutChartRef = useRef();
    const pieChartRef = useRef();
    const polarAreaChartRef = useRef();
    const redarChartRef = useRef();
    const scatterChartRef = useRef();

    const [show, setShow] = useState(false);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [showDropdown, setShowDropdown] = useState(false);

    const handleMouseEnter = () => {
        setShowDropdown(true);
    };

    const handleMouseLeave = () => {
        setShowDropdown(false);
    };



    const [isLoading, setIsLoading] = useState(false)
    const [chartData, setChartData] = useState([])
    const [startFormDate, setStartFormDate] = useState(null);
    const [startToDate, setStartToDate] = useState(null);
    const [portfolioSlug, setPortfolioSlug] = useState(null)
    const [oldDate, setOldDate] = useState({
        from: null,
        to: null,
    });
    const [formData, setFormData] = useState({
        indicators: null,
        indicatorsTow: null,
        from: '',
        to: '',
        chartType: '',
        isAutoChart: true,
        portfolio: null,
        assets: null,
        tableType: ''
    });
    const { toPDF, targetRef } = usePDF({
        method: "save",
        filename: "usepdf-example.pdf",
        page: { margin: Margin.SMALL },
        canvas: {
            mimeType: "image/jpeg",
            logging: true,
        },
        overrides: {
            pdf: {
                orientation: 'div',
                compress: true,
                floatPrecision: "smart",
                putOnlyUsedFonts: true
            }
        }
    });
    useEffect(() => {
        dispatch(getAllReports(setChartData, setIsLoading, setIsLoadings))
    }, [])

    useEffect(() => {
        if (formData && formData.portfolio && formData.portfolio?.slug) {
            dispatch(fetchAssetsReportingDetails(null, formData.portfolio.slug, 'reporting'))
        }
    }, [formData.portfolio?.slug])

    useEffect(() => {
        // setChartData(reportsData)
        const date = localStorage.getItem('date')
        if (date) {
            const getDate = JSON.parse(date)
            setOldDate(input => ({ ...input, from: getDate.from, to: getDate.to }))
            // setStartFormDate(moment().subtract(1,'month').format("YYYY-MM"))
        }
        console.log('reportsData==========>', reportsData)
    }, [reportsData])


    const save = (charts, setCheckbox1Checked, setCheckbox2Checked, analytics, assetOptions) => {
        // setIsLoading(true)
        if (formData.portfolio === null) {
            dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Please select any one Portfolio' }))
            setCheckbox1Checked(true)
            setCheckbox2Checked(false)
            setIsLoading(false)
        } else if (formData.chartType === '') {
            dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Please select any one Chart-Type' }))
            setCheckbox1Checked(true)
            setCheckbox2Checked(false)
            setIsLoading(false)
        } else {
            let indicator = !formData.indicators ? [] : formData.indicators.map((item) => {
                return item.value
            })
            let fetchAsset = !formData.assets ? assetOptions.map((item) => item.value) : formData.assets.map((item) => {
                return item.value
            })
            const chartType = charts !== '' ? charts : formData.chartType
            let chartReport;
            if (!indicator.length) {
                const allAsset = formData.assets !== null ? formData.assets : Array.isArray(assetsDetails) ? assetsDetails.map(item => item.id) : [];
                const unitedNation = Array.isArray(allAnalytics) ? allAnalytics.find((item) => item.source === 'World Bank') : null
                const unitedNationIndicators = unitedNation ? unitedNation.indicators : [];
                const indicatorOptions = unitedNationIndicators && unitedNationIndicators?.length >= 1 ? unitedNationIndicators?.map((item) => item?.analyticId) : [];
                chartReport = [
                    {
                        portfolio: formData.portfolio?.value,
                        fromDate: formData.from === "" ? "" : moment(formData.from).format('YYYY'),
                        toDate: formData.to === "" ? "" : moment(formData.to).format('YYYY'),
                        analytics: analytics,
                        assets: allAsset,
                        save: true,
                        type: formData.chartType,
                        isAutoChart: formData.isAutoChart,
                    }
                ]
            } else {
                chartReport = [
                    {
                        portfolio: formData.portfolio?.value,
                        fromDate: formData.from === "" ? "" : moment(formData.from).format('YYYY'),
                        toDate: formData.to === "" ? "" : moment(formData.to).format('YYYY'),
                        analytics: analytics,
                        assets: fetchAsset,
                        save: true,
                        type: formData.chartType,
                        isAutoChart: formData.isAutoChart,
                    }
                ]
            }
            dispatch(fetchReports(chartReport, setChartData, chartType, setIsLoading, formData.isAutoChart, indicator, formData.portfolio?.value, chartData, false, false))
            setFormData(input => ({
                ...input,
                indicators: null,
                from: '',
                to: '',
                isAutoChart: true,
                assets: null
            }))
            handleClose()
        }


    }

    const [showAlertDialog, setShowAlertDialog] = useState(false)
    const [deleteChartId, setDeleteChartId] = useState(null)
    const handleCancel = () => {
        setDeleteChartId(null);
        setShowAlertDialog(false);
    }
    const handleContinue = async () => {
        // let updateData = []
        // chartData.forEach((item) => {
        //     if (item.id !== id) {
        //         updateData.push({ ...item })
        //     }
        // })
        await dispatch(deleteReports(deleteChartId, setChartData));
    }

    const deleteChart = async (id) => {
        // let updateData = []
        // chartData.forEach((item) => {
        //     if (item.id !== id) {
        //         updateData.push({ ...item })
        //     }
        // })
        setShowAlertDialog(true)
        setDeleteChartId(id)
        // await dispatch(deleteReports(id, setChartData));
    }

    const [oldChartData, setOldChartData] = useState(null)
    const onApplyData = () => {
        if (oldDate.from === null || oldDate.to === null) {
            dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Please select from and to date' }))
        } else {
            if (oldDate.from !== null && oldDate.to !== null) {
                let prepareOldData = chartData.map((item, index) => {
                    let data = {
                        chartId: item.chartId,
                        portfolio: item?.portfolioId ? item?.portfolioId : '',
                        analytics: item.analytics,
                        assets: item.assets,
                        fromDate: item.fromDate === "" ? "" : moment(item.fromDate).format('YYYY'),
                        toDate: item.toDate === "" ? "" : moment(item.toDate).format('YYYY'),
                        oldFromDate: item.fromDate === "" ? "" : moment(item.fromDate).format('YYYY'),
                        oldToDate: item.fromDate === "" ? "" : moment(item.fromDate).format('YYYY'),
                        save: true,
                        type: item.type,
                        isAutoChart: item.isAutoChart,
                        isChangeDate: true,
                        index,
                    }
                    return data
                })
                setOldChartData(prepareOldData)
                let getData = chartData.map((item, index) => {
                    let data = {
                        chartId: item.chartId,
                        portfolio: item?.portfolioId ? item?.portfolioId : '',
                        analytics: item.analytics,
                        assets: item.assets,
                        fromDate: oldDate.from === "" ? "" : moment(oldDate.from).format('YYYY'),
                        toDate: oldDate.to === "" ? "" : moment(oldDate.to).format('YYYY'),
                        oldFromDate: item.fromDate,
                        oldToDate: item.toDate,
                        save: true,
                        type: item.type,
                        isAutoChart: item.isAutoChart,
                        isChangeDate: true,
                        index,
                    }
                    return data
                })
                const chartReport = getData;
                const setValueInCookies = JSON.stringify(prepareOldData)
                localStorage.setItem('reportData', btoa(setValueInCookies));
                setOldDate(input => ({ ...input, from: moment(oldDate.from).format('YYYY'), to: moment(oldDate.to).format('YYYY') }))
                localStorage.setItem('date', JSON.stringify({ from: moment(oldDate.from).format('YYYY-MM-DD'), to: moment(oldDate.to).format('YYYY-MM-DD') }))
                dispatch(fetchReports(chartReport, setChartData, 'Line Chart', setIsLoading, formData.isAutoChart, [], formData.portfolio?.value, chartData, true, false))
                setStartToDate(null)
            }
        }
    }

    const resetData = () => {
        const reportData = localStorage.getItem('reportData');
        // console.log('Please reset chart chartData', chartData)
        // let getData = chartData.map((item, index) => {
        //     let data = {
        //         chartId: item.chartId,
        //         portfolio: item?.portfolioId ? item?.portfolioId : '',
        //         analytics: item.analytics,
        //         assets: item.assets,
        //         fromDate: item.fromDate ?? '',
        //         toDate: item.toDate ?? '',
        //         save: item.save,
        //         type: item.type,
        //         isAutoChart: item.isAutoChart,
        //         index,
        //     }
        //     return data
        // })
        if (reportData) {
            const atobOldChartData = atob(reportData)
            const oldChartData = JSON.parse(atobOldChartData)
            const chartReport = oldChartData
            setStartFormDate(null)
            setStartToDate(null)
            localStorage.removeItem('date')
            setOldDate(input => ({ from: null, to: null }))
            dispatch(fetchReports(chartReport, setChartData, 'Line Chart', setIsLoading, formData.isAutoChart, [], formData.portfolio?.value, chartData, false, false))
            setOldChartData(null)
            localStorage.removeItem('reportData');
        } else {
            setOldDate(input => ({ from: null, to: null }))
        }

    }

    const mainChartRef = useRef();
    const [progress, setProgress] = useState(0); // State for progress
    const [downloading, setDownloading] = useState(false);

    // download svg To Image
    const svgToImage = async (svgElement) => {
        const svgString = new XMLSerializer().serializeToString(svgElement);
        const img = new Image();
        img.src = 'data:image/svg+xml;base64,' + btoa(svgString);
        await new Promise((resolve) => (img.onload = resolve));
        return img;
    };
    // download table To Image
    const tableToImage = async (tableElement) => {
        try {
            // Create a wrapper div to add margin
            const wrapper = document.createElement('div');
            // wrapper.style.margin = `${10}px`;
            wrapper.appendChild(tableElement.cloneNode(true));
            // Use html2canvas to capture the table as an image
            const canvas = await html2canvas(tableElement);

            // Convert the canvas to a data URL
            const dataUrl = canvas.toDataURL('image/png');

            // Create an image element
            const img = new Image();

            // Set the source of the image to the data URL
            img.src = dataUrl;

            // Wait for the image to load
            await new Promise((resolve) => (img.onload = resolve));

            return img;
        } catch (error) {
            console.error('Error converting table to image:', error);
            throw error;
        }
    };
    // download generate Base64 Data
    const generateBase64Data = async (chartRefArray) => {
        const base64ImagesArray = [];

        for (let i = 0; i < chartRefArray.length; i++) {
            const chartRef = chartRefArray[i];
            if (chartRef) {
                const rect = chartRef.getBoundingClientRect();

                let chartImage
                if (chartRef?.classList?.value?.includes('w-full caption-bottom text-sm min-w-[1000px] isTable')) {
                    // chartImage = await tableToImage(chartRef);
                    // Call the tableToImage function
                    chartImage = await tableToImage(chartRef)
                } else {
                    const svgData = chartRef.getElementsByTagName('svg')[0]
                    chartImage = await svgToImage(svgData);
                    // chartImage = await svgToImage(chartRef);
                }

                base64ImagesArray.push({
                    base64Data: chartImage.src,
                    width: rect.width,
                    height: rect.height,
                });
            }
        }

        return base64ImagesArray;
    };
    // download png
    const downloadImage = (combinedCanvas) => {
        setIsLoading(true);
        // Simulate progress with a timeout
        setTimeout(() => {
            setDownloading(false); // Finish downloading
            setProgress(100); // Set progress to 100%
        }, 500); // You can adjust the timeout as needed
        const link = document.createElement('a');
        link.href = combinedCanvas.toDataURL('image/png'); // Call toDataURL here
        link.download = 'combined-charts.png';
        link.click();

        setIsLoading(false);
    };
    // download convert Svg To Pdf
    const convertSvgToPdf = async (chartContainers) => {
        const svgElements = chartContainers;
        const pdfOptions = { margin: 10 };

        // Create a container for SVG elements
        const container = document.createElement('div');
        svgElements.forEach(svg => container.appendChild(svg.cloneNode(true)));

        // Convert HTML (including SVG) to PDF
        // html2pdf(container, pdfOptions)
        //     .then(pdf => {
        //         // Save the Blob as a PDF file
        //         saveAs(pdf.output('blob'), 'output.pdf');
        //     })
        //     .catch(error => {
        //         console.error('Error converting to PDF:', error);
        //     });
    };
    // download All table and chart
    const handleDownload = async (isImages, isPdf) => {
        setDownloading(true); // Start downloading
        setProgress(0); // Reset progress
        const gap = 10;

        const mainChartContainer = mainChartRef.current;
        const chartContainers = mainChartContainer.getElementsByClassName('css-13aj3tc-MuiChartsSurface-root');

        const numRows = 2;
        const numCols = Math.ceil(chartContainers.length / numRows);

        const base64ImagesArray = await generateBase64Data(chartContainers);

        if (isImages) {
            const combinedCanvas = await combineImages(base64ImagesArray, numCols, numRows, gap, 1);
            downloadImage(combinedCanvas);
        }

        if (isPdf) {
            await convertSvgToPdf(chartContainers);
        }
    };
    // create canvas
    const combineImages = async (base64ImagesArray, numCols, numRows, gap, borderWidth) => {

        const combinedCanvas = document.createElement('canvas');
        const ctx = combinedCanvas.getContext('2d');
        let totalHeight = 0;
        let maxWidth = 0;

        // Calculate the total height and maximum width, including borders and gaps
        for (let i = 0; i < base64ImagesArray.length; i++) {
            const rect = base64ImagesArray[i];
            totalHeight += rect.height + borderWidth * 2 + gap;

            // Track the maximum width within the images
            maxWidth = Math.max(maxWidth, rect.width + borderWidth * 2);
        }

        // Set the canvas width based on the maximum width of images
        combinedCanvas.width = maxWidth;

        // Set the canvas height including borders and gaps
        combinedCanvas.height = totalHeight;

        // Fill the canvas with the background color
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, combinedCanvas.width, combinedCanvas.height);

        let currentY = borderWidth; // Start drawing from the top border

        for (let i = 0; i < base64ImagesArray.length; i++) {
            const rect = base64ImagesArray[i];

            const img = new Image();
            img.src = rect.base64Data;
            await new Promise((resolve) => (img.onload = resolve));

            const x = (combinedCanvas.width - rect.width - borderWidth * 2) / 2; // Center the image horizontally
            const y = currentY;

            // Draw the image with borders and gaps
            ctx.drawImage(img, x + borderWidth, y + borderWidth, rect.width, rect.height);

            // Draw borders around the image
            ctx.strokeStyle = 'black';
            ctx.lineWidth = borderWidth;
            ctx.strokeRect(x, y, rect.width + borderWidth * 2, rect.height + borderWidth * 2);

            // Move to the next line (new row)
            currentY += rect.height + borderWidth * 2 + gap;
        }

        return combinedCanvas;
    };

    // download Progress 
    useEffect(() => {
        if (downloading) {
            const interval = setInterval(() => {
                // Simulate progress increase
                setProgress((prevProgress) => (prevProgress < 90 ? prevProgress + 10 : prevProgress));
            }, 500);

            return () => clearInterval(interval);
        }
    }, [downloading]);

    // download table and chart
    const handleDownloads = async (isImages, isPdf) => {
        const chartRefs = [lineChartRef, barChartRef, bubbleChartRef, doughnutChartRef, pieChartRef, polarAreaChartRef, redarChartRef, scatterChartRef]; // Add your other refs here
        const mainChartContainer = mainChartRef.current;
        const chartContainers = mainChartContainer.getElementsByTagName('CANVAS'); // Assuming 'chart_main' is the class name of chart container
        const base64ImagesArray = [];
        for (let i = 0; i < chartContainers.length; i++) {
            const chartRef = chartContainers[i];
            if (chartRef) {
                // const chartImage = await ChartJsImage.toDataUrl(chartRef.current.chartInstance);
                // const chartCanvas = chartRef.current.canvas;
                const chartCanvas = chartRef;
                const chartImage = await html2canvas(chartCanvas);

                // Convert the canvas image to a base64 data URL
                const base64Image = chartImage.toDataURL('image/png');

                // Now you have the base64 image that you can use or download

                base64ImagesArray.push({ base64Data: base64Image });
            }
        }

        if (isImages) {
            base64ImagesArray.forEach((data) => {
                const link = document.createElement('a');
                link.href = data.base64Data;
                const randomNumber = generateSixDigitNumber()
                link.download = `${randomNumber}-chart.png`;
                link.click();
            })
        }
        if (isPdf) {
            const response = await downloadPDF(base64ImagesArray)

            if (response) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = `${generateSixDigitNumber()}.pdf`;  // You can also get this from the 'Content-Disposition' header in the response
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            }
        }

    };

    function generateSixDigitNumber() {
        return Math.floor(100000 + Math.random() * 900000);
    }
    // console.log('loading', isLoadings)


    const renderChart = (item, index) => {
        switch (item.type) {
            case "Line Chart":
                return <BasicLineChart analyticReports={item} isAutoChart={item.isAutoChart} updateId={updateId} isReports={true} index={index} refEl={lineChartRef} />;
            case "Bar Chart":
                return <BarChartCustom analyticReports={item} isAutoChart={item.isAutoChart} updateId={updateId} isReports={true} index={index} refEl={barChartRef} />;
            case "Pie Chart":
                return <PieChartCustom analyticReports={item} isAutoChart={item.isAutoChart} updateId={updateId} isReports={true} index={index} refEl={pieChartRef} />;
            case "Doughnut Chart":
                return <DoughnutChart analyticReports={item} isAutoChart={item.isAutoChart} updateId={updateId} isReports={true} index={index} refEl={doughnutChartRef} />;
            case "PolarArea Chart":
                return <StackedAreaChart analyticReports={item} isAutoChart={item.isAutoChart} updateId={updateId} isReports={true} index={index} refEl={polarAreaChartRef} />;
            case "Radar Chart":
                return <RadarCharts analyticReports={item} isAutoChart={item.isAutoChart} updateId={updateId} isReports={true} index={index} refEl={redarChartRef} />;
            case "Scatter Chart":
                return <BasicScatter analyticReports={item} isAutoChart={item.isAutoChart} updateId={updateId} isReports={true} index={index} refEl={scatterChartRef} />;
            default:
                return null;
        }
    };
    console.log(oldDate)
    return (
        <>
            <div className="content-main-wrapper !pt-0">
                <div className="content-head mb-6">
                    <div className='flex items-center justify-between gap-4 flex-wrap' action="">
                        <div className="flex items-center gap-4 flex-wrap">
                            <Button size="sm" className="min-w-[98px]" variant="" onClick={handleShow}>
                                <span><IconAdd className="w-4 h-4" /></span>  Chart
                            </Button>
                            <div className="flex items-center gap-4">
                                <label htmlFor="" className='text-textV1 text-xs font-500'>From</label>

                                <DatePickerCustomInput onDateSelect={(date) => {
                                    setStartFormDate(date);
                                    setOldDate({ ...oldDate, from: date })
                                }}
                                    placeholderText='YYYY-MM-DD'
                                    selectedFromDate={startFormDate}
                                    value={oldDate.from}
                                />

                                {/* <FromDatePicker
                                    onDateSelect={(date) => {
                                        setStartFormDate(date);
                                        setOldDate({ ...oldDate, from: date })
                                    }}
                                    selected={startFormDate}
                                    selectsStart
                                    dateFormat={'yyyy-MM-dd'}
                                    placeholderText='YYYY-MM-DD'
                                    startDate={startFormDate}
                                    endDate={startToDate}
                                    isFrom={true}
                                /> */}
                            </div>
                            <div className="flex items-center gap-4">
                                <label htmlFor="" className='text-textV1 text-xs font-500'>To</label>
                                <DatePickerCustomInput onDateSelect={(date) => {
                                    setStartToDate(date);
                                    setOldDate({ ...oldDate, to: date });
                                }}
                                    placeholderText='YYYY-MM-DD'
                                    minDate={startFormDate}
                                    selectedFromDate={startFormDate}
                                    value={oldDate.to}
                                />
                                {/* <ToDatePicker
                                    onDateSelect={(date) => {
                                        setStartToDate(date);
                                        setOldDate({ ...oldDate, to: date });
                                    }}
                                    selected={startToDate}
                                    selectsEnd
                                    dateFormat={'yyyy-MM-dd'}
                                    placeholderText='YYYY-MM-DD'
                                    startDate={startFormDate}
                                    endDate={startToDate}
                                    minDate={startFormDate}
                                    isTo={true}
                                /> */}
                            </div>
                            {/* || (startToDate === null) || (startFormDate === null) */}
                            <Button size="sm" disabled={(!chartData.length) || (oldDate.from === null) || (oldDate.to === null)} className="min-w-[98px] hover:bg-[#F5F5F5]" type="submit" variant="outline" onClick={() => onApplyData()}>
                                Apply
                            </Button>
                            <Button size="sm" disabled={(!chartData.length) || (oldDate.from === null) || (oldDate.to === null)} className="min-w-[98px] border-red text-red hover:bg-[#F5F5F5]" type="submit" variant="outline" onClick={() => resetData()}>
                                Reset Charts
                            </Button>
                        </div>
                        <div className="flex items-center gap-4 flex-wrap">
                            {/* <Button size="sm" className="min-w-[98px] hover:bg-[#F5F5F5]"
                                disabled={!chartData.length}
                                variant="outline" onClick={() => toPDF()}>
                                <span><DownloadIcon className="w-4 h-4" /></span>Download All
                            </Button> */}
                            <Button size="sm" className="min-w-[98px] hover:bg-[#F5F5F5]"
                                disabled={!chartData.length}
                                variant="outline" onClick={() => handleDownload(true, false)}>
                                <span><DownloadIcon className="w-4 h-4" /></span>Download All
                            </Button>
                            {downloading && (<Progress animated value={progress} className='h-[8px] w-[85%]' />)}
                        </div>
                    </div>
                </div>
                {/* <div className="table-content mb-6">
                    <Card className={"border border-borderV1 p-3 md:p-3 mb-6"}>
                        <div className="heading mb-4 flex items-center justify-between gap-4">
                            <div className="">
                                <h5 className='text-sm text-textV2 font-500 mb-1'>Portfolio: <span>Test</span></h5>
                                <p className='text-xs text-textV1 font-500'>Horizontal Exposure Table</p>
                            </div>
                            <div className="items-center gap-2 md:flex hidden">
                                <Button variant="outline" size="sm" className="min-w-[100px]">
                                    <span><Download className='w-4 h-4' /></span> Download
                                </Button>
                                <Button variant="outline" size="sm" className="min-w-[100px]">
                                    <span><Trash className='w-4 h-4' /></span>  Delete
                                </Button>
                            </div>
                            <div className="items-center gap-2  md:hidden flex">
                                <Button variant="outline" size="sm" className="w-8 h-8 p-0">
                                    <span><Download className='w-4 h-4' /></span>
                                </Button>
                                <Button variant="outline" size="sm" className="w-8 h-8 p-0">
                                    <span><Trash className='w-4 h-4' /></span>
                                </Button>
                            </div>
                        </div>
                        <div className="table-wrapper">
                            <div className="rounded-sm border  overflow-hidden">
                                <Table className="min-w-[1000px]">
                                    <TableHeader>
                                        {table.getHeaderGroups().map((headerGroup) => (
                                            <TableRow key={headerGroup.id} className="">
                                                {headerGroup.headers.map((header) => {
                                                    return (
                                                        <TableHead className="bg-backgroundV1" key={header.id}>
                                                            {header.isPlaceholder
                                                                ? null
                                                                : flexRender(
                                                                    header.column.columnDef.header,
                                                                    header.getContext()
                                                                )}
                                                        </TableHead>
                                                    )
                                                })}
                                            </TableRow>
                                        ))}
                                    </TableHeader>
                                    <TableBody>
                                        {table.getRowModel().rows?.length ? (
                                            table.getRowModel().rows.map((row) => (
                                                <TableRow
                                                    key={row.id}
                                                    data-state={row.getIsSelected() && "selected"}
                                                >
                                                    {row.getVisibleCells().map((cell) => (
                                                        <TableCell className="text-xs" key={cell.id}>
                                                            {flexRender(
                                                                cell.column.columnDef.cell,
                                                                cell.getContext()
                                                            )}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={columns.length}
                                                    className="h-24 text-center"
                                                >
                                                    No results.
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </Card>
                    <Card className={"border border-borderV1 p-3 md:p-3"}>
                        <div className="heading mb-4 flex items-center justify-between gap-4">
                            <div className="">
                                <h5 className='text-sm text-textV2 font-500 mb-1'>Portfolio: <span>Test</span></h5>
                                <p className='text-xs text-textV1 font-500'>Horizontal Exposure Table</p>
                            </div>
                            <div className="items-center gap-2 md:flex hidden">
                                <Button variant="outline" size="sm" className="min-w-[100px]">
                                    <span><Download className='w-4 h-4' /></span> Download
                                </Button>
                                <Button variant="outline" size="sm" className="min-w-[100px]">
                                    <span><Trash className='w-4 h-4' /></span> Delete
                                </Button>
                            </div>
                            <div className="items-center gap-2  md:hidden flex">
                                <Button variant="outline" size="sm" className="w-8 h-8 p-0">
                                    <span><Download className='w-4 h-4' /></span>
                                </Button>
                                <Button variant="outline" size="sm" className="w-8 h-8 p-0">
                                    <span><Trash className='w-4 h-4' /></span>
                                </Button>
                            </div>
                        </div>
                        <div className="table-wrapper">
                            <div className="rounded-sm border  overflow-hidden">
                                <Table className="min-w-[1000px]">
                                    <TableHeader>
                                        {table.getHeaderGroups().map((headerGroup) => (
                                            <TableRow key={headerGroup.id} className="">
                                                {headerGroup.headers.map((header) => {
                                                    return (
                                                        <TableHead className="bg-backgroundV1" key={header.id}>
                                                            {header.isPlaceholder
                                                                ? null
                                                                : flexRender(
                                                                    header.column.columnDef.header,
                                                                    header.getContext()
                                                                )}
                                                        </TableHead>
                                                    )
                                                })}
                                            </TableRow>
                                        ))}
                                    </TableHeader>
                                    <TableBody>
                                        {table.getRowModel().rows?.length ? (
                                            table.getRowModel().rows.map((row) => (
                                                <TableRow
                                                    key={row.id}
                                                    data-state={row.getIsSelected() && "selected"}
                                                >
                                                    {row.getVisibleCells().map((cell) => (
                                                        <TableCell className="text-xs" key={cell.id}>
                                                            {flexRender(
                                                                cell.column.columnDef.cell,
                                                                cell.getContext()
                                                            )}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={columns.length}
                                                    className="h-24 text-center"
                                                >
                                                    No results.
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </Card>
                </div> */}
                <div className="chart-content" id='reportTable' >
                    {isLoading ? <Loading /> : chartData.length >= 1 ? <div className="">
                        <div ref={mainChartRef}>
                            {chartData.map((item, index) => {
                                if (item.type === 'Vertical Exposure Table') {
                                    return <Fragment key={item.id || index}>
                                        <VerticalExposureTable item={item} deleteChart={deleteChart} handleDownloads={handleDownloads} Index={index} />
                                        {/* <Alert title="Are you absolutely sure?" description="This action cannot be undone. This will permanently delete your Vertical Exposure Table data from our servers." handleClickContinue={handleContinue} handleClickCancel={handleCancel} showAlertDialog={showAlertDialog} setShowAlertDialog={setShowAlertDialog} /> */}
                                    </Fragment>
                                } else if (item.type === 'Horizontal Exposure Table') {
                                    return <Fragment key={item.id || index}>
                                        <HorizontalExposureTable item={item} deleteChart={deleteChart} handleDownloads={handleDownloads} Index={index} />
                                        {/* <Alert title="Are you absolutely sure?" description="This action cannot be undone. This will permanently delete your Horizontal Exposure Table data from our servers." handleClickContinue={handleContinue} handleClickCancel={handleCancel} showAlertDialog={showAlertDialog} setShowAlertDialog={setShowAlertDialog} /> */}
                                    </Fragment>
                                }
                                return null
                            })}
                            <div className='grid grid-cols-1 lg:grid-cols-2 gap-6' ref={targetRef}>
                                {
                                    chartData.map((item, index) => {
                                        if (item.type !== 'Vertical Exposure Table' && item.type !== 'Horizontal Exposure Table') {
                                            return (
                                                <Fragment key={item.id || index}>
                                                    <Card className={" shadow-md p-3 md:p-3 relative"} >
                                                        <div className="heading mb-4 flex items-center justify-between gap-4">
                                                            <div className="">
                                                                <h5 className='text-sm text-textV2 font-500 mb-1'>Portfolio: <span>{item?.portfolioName}</span></h5>
                                                                <p className='text-xs text-textV1 font-500'>{item?.type}</p>
                                                            </div>
                                                            <div className="flex items-center gap-2">
                                                                <Button variant="outline" size="sm" className="w-8 h-8 p-0 !border-red hover:bg-[#F5F5F5]" onClick={() => deleteChart(item.chartId)}>
                                                                    <span><Trash className='w-4 h-4 !text-red' /></span>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        <div className="chart-wrapper w-full h-[340px] flex justify-center items-center">
                                                            {renderChart(item, index)}
                                                        </div>
                                                    </Card>
                                                </Fragment>
                                            )
                                        } else {
                                            return null
                                        }
                                    })
                                }
                                <Alert title="Are you absolutely sure?" description="This action cannot be undone. This will permanently delete your Chart data from our servers." handleClickContinue={handleContinue} handleClickCancel={handleCancel} showAlertDialog={showAlertDialog} setShowAlertDialog={setShowAlertDialog} />
                            </div>
                        </div>
                    </div> : <div className=''>
                        <div className=''>
                            <div className='mt-4' >
                                <div className={'rounded-sm border border-borderV1 grid grid-cols-1 gap-5 text-center p-5'}>
                                    <div>No Data Available</div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
            <ReportingModel show={show} onHide={handleClose} save={save} formData={formData} setFormData={setFormData} setIsLoading={setIsLoading} setPortfolioSlug={setPortfolioSlug} isReports={true} />
        </>
    )
}

export default ReportingMain





// const downloadPdf = async (base64ImagesArray) => {
//     setIsLoading(true);

//     const pdf = new jsPDF({
//         orientation: 'l',
//         unit: 'mm',
//         format: 'a4',
//     });

//     for (let i = 0; i < base64ImagesArray.length; i++) {
//         const imageData = base64ImagesArray[i].base64Data;
//         const width = base64ImagesArray[i].width;
//         const height = base64ImagesArray[i].height;

//         if (i > 0) {
//             pdf.addPage();
//         }

//         // Convert base64 to Uint8Array
//         const uint8Array = new Uint8Array(atob(imageData.split(',')[1]).split('').map(char => char.charCodeAt(0)));

//         // Add image to PDF
//         pdf.addImage(imageData, 'PNG', 0, 0, width, height);
//     }

//     pdf.save(`${generateSixDigitNumber()}.pdf`);
//     setIsLoading(false);
// };